

// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// import tw from 'tailwind-styled-components';

// const GraphContainer = tw.div`bg-white md:w-full md:h-72 px-2 py-4 `;

// const Graph = ({ graphData }) => {

//     const keys = Object.keys(graphData?.monthlyWiseData || {});


//     const allMonths = new Set();
//     keys.forEach(key => {
//         graphData.monthlyWiseData[key].forEach(entry => {
//             allMonths.add(entry.month);
//         });
//     });


//     const labels = Array.from(allMonths).sort();


//     const colors = [
//         'orange', '#22c55e', 'blue', 'red', 'purple', 'pink', 'yellow',
//         'cyan', 'teal', 'magenta', 'lime', 'indigo'
//     ];


//     const datasets = keys.map((key, index) => {
//         const data = labels.map(month => {
//             const entry = graphData.monthlyWiseData[key].find(e => e.month === month);
//             return entry ? entry.count : 0;
//         });

//         return {
//             label: key.charAt(0).toUpperCase() + key.slice(1),
//             data,
//             borderColor: colors[index % colors.length],
//             fill: false,
//         };
//     });


//     const data = {
//         labels,
//         datasets,
//     };


//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//     };

//     return (
//         <GraphContainer>
//             <Line data={data} options={options} />
//         </GraphContainer>
//     );
// };

// export default Graph;

// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// import tw from 'tailwind-styled-components';

// const GraphContainer = tw.div`bg-white md:w-full md:h-72 px-2 py-4`;

// const Graph = ({ graphData }) => {
//     const keys = Object.keys(graphData?.monthlyWiseData || {});


//     const firstKey = keys[0];
//     const orderedMonths = graphData.monthlyWiseData[firstKey].map(item => item.month);

//     const colors = [
//         'orange', '#22c55e', 'blue', 'red', 'purple', 'pink', 'yellow',
//         'cyan', 'teal', 'magenta', 'lime', 'indigo'
//     ];

//     const datasets = keys.map((key, index) => {
//         const data = orderedMonths.map(month => {
//             const entry = graphData.monthlyWiseData[key].find(e => e.month === month);
//             return entry ? entry.count : 0;
//         });

//         return {
//             label: key.charAt(0).toUpperCase() + key.slice(1),
//             data,
//             borderColor: colors[index % colors.length],
//             fill: false,
//         };
//     });

//     const data = {
//         labels: orderedMonths,
//         datasets,
//     };

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//     };

//     return (
//         <GraphContainer>
//             <Line data={data} options={options} />
//         </GraphContainer>
//     );
// };

// export default Graph;


// src/components/Graph.jsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import tw from 'tailwind-styled-components';

const GraphContainer = tw.div`bg-white md:w-full md:h-72 px-2 py-4 `;

const data = {
    labels: [1, 2, 3, 4, 5, 6],
    datasets: [
        {
            label: 'Online',
            data: [5, 10, 15, 10, 25, 30],
            borderColor: 'orange',
            fill: false,
        },
        {
            label: 'Store',
            data: [10, 5, 7, 15, 10, 20],
            borderColor: '#22c55e',
            fill: false,
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
};

const Graph = () => {
    return (
        <GraphContainer>
            <Line data={data} options={options} />
        </GraphContainer>
    );
};

export default Graph;
