import Images from "Images";

export const benefits = [
  {
    title: "Improved Customer Segmentation",
    image: Images.customerSegmentation,
    description:
      "AI-powered content creation and geo-targeting capabilities allow banks to better segment their customers. This results in more personalized and relevant marketing campaigns, enhancing customer targeting and engagement.",
  },
  {
    title: "Seamless Omni-Channel Communication",
    image: Images.publicRelations,
    description:
      "Maintain consistent and integrated communication across all channels, ensuring that customers receive uniform messaging, whether through email, SMS, social media, or in-app notifications.",
  },
  {
    title: "Enhanced Customer Experience",
    image: Images.onboarding,
    description:
      "SEVO’s personalized campaigns based on customer data deliver a tailored experience, increasing customer satisfaction, loyalty, and retention rates for banks and financial institutions.",
  },
  {
    title: "Data-Driven Decision Making",
    image: Images.monitor,
    description:
      "Leverage Reporting & Analytics to gain actionable insights into customer behavior and campaign performance, allowing for data-driven decisions that optimize future marketing efforts.",
  },
  {
    title: "Automation for Increased Efficiency",
    image: Images.productive,
    description:
      "Sales & Marketing Automation streamlines customer engagement processes, follow-ups on leads, and the upselling of financial products, significantly saving time and resources.",
  },
];


export const features = [
  {
    title: "Loan Promotion Campaign",
    useCase: "Promote new loan offerings to existing and potential customers.",
    mechanics:
      "Banks use Bulk SMS and Email Campaigns to promote new loan offerings, targeting specific regions and demographics with Geo-Location Campaigns. Social media ads on Facebook and Instagram feature personalized video content.",
    image: Images.religion_img_one,
    benefit:
      "Increased loan applications, improved customer targeting for specific financial products, and higher engagement through personalized messaging.",
  },
  {
    title: "Credit Card Upsell Campaign",
    useCase: "Upsell existing customers with upgraded credit card options.",
    mechanics:
      "Using SEVO’s Sales & Marketing Automation, banks send personalized Email and SMS offers to existing customers based on spending behavior. AI Content Creation crafts tailored messages, and Voice Broadcast follows up with high-potential customers.",
    image: Images.religion_img_two,
    benefit:
      "Improved upsell conversion rates, better customer satisfaction through tailored offers, and increased credit card revenue.",
  },
  {
    title: "New Account Opening Drive",
    useCase: "Attract new customers to open accounts through a multi-channel marketing approach.",
    mechanics:
      "Banks launch Social Media Campaigns across Instagram, Facebook, and TikTok with incentives like cash-back bonuses. Bulk SMS and Email Messaging support the campaign by driving traffic to online account opening forms.",
    image: Images.religion_img_three,
    benefit:
      "Expanded customer base, increased digital engagement, and faster account opening processes.",
  },
  {
    title: "Loyalty Rewards for High-Value Customers",
    useCase: "Reward high-value customers with loyalty points, cashback offers, and personalized deals.",
    mechanics:
      "Financial institutions use SEVO’s Loyalty Management Module to notify customers of their rewards through Bulk SMS and Email Campaigns and gather feedback via Surveys.",
    image: Images.religion_img_four,
    benefit:
      "Enhanced customer retention, improved brand loyalty, and higher engagement from high-value customers.",
  },
  {
    title: "Financial Wellness Seminar Promotion",
    useCase: "Promote financial wellness seminars to increase customer education.",
    mechanics:
      "Banks host seminars promoted via Social Media Marketing on Facebook and LinkedIn. Bulk SMS invites customers to webinars, and the Meetings & Appointment Module allows for one-on-one financial consultations.",
    image: Images.religion_img_five,
    benefit:
      "Higher customer education, improved financial literacy, and deeper customer relationships through personalized financial advice.",
  },
  {
    title: "Personal Loan Reminders",
    useCase: "Send reminders about personal loan payments to encourage timely repayments.",
    mechanics:
      "Banks use Sales & Marketing Automation to send automated reminders via SMS, Email, and Voice Broadcast, with AI Content Creation ensuring friendly messaging.",
    image: Images.religion_img_six,
    benefit:
      "Reduced loan delinquency rates, improved customer service, and higher payment collection.",
  },
  {
    title: "Lead Generation for High-Net-Worth Clients",
    useCase: "Identify high-net-worth individuals interested in premium banking services.",
    mechanics:
      "Banks run Lead Generation Campaigns using USSD Menu Builder and targeted Email Campaigns and Social Media Ads on LinkedIn.",
    image: Images.religion_img_seven,
    benefit:
      "Increased acquisition of high-value customers, improved targeting, and enhanced client relationships.",
  },
  {
    title: "New Product Awareness Campaign",
    useCase: "Promote new financial products through multi-channel campaigns.",
    mechanics:
      "Banks use Bulk Email, SMS, and Social Media Marketing on Instagram and Facebook to promote new products, leveraging Geo-Location Campaigning for regional promotions.",
    image: Images.religion_img_eight,
    benefit:
      "Broader customer awareness, increased product uptake, and more efficient targeting through location-based campaigns.",
  },
  {
    title: "Fraud Awareness Campaign",
    useCase: "Educate customers on fraud prevention using multi-channel campaigns.",
    mechanics:
      "Banks run campaigns using Bulk SMS, Email Messaging, Voice Broadcast, and Social Media Ads on Facebook and Instagram, utilizing AI-powered Content Tools for engaging visuals.",
    image: Images.religion_img_nine,
    benefit:
      "Increased customer trust, reduced instances of fraud, and improved customer safety.",
  },
  {
    title: "Banking App Adoption Campaign",
    useCase: "Promote the adoption of mobile banking apps for increased digital engagement.",
    mechanics:
      "Banks use Bulk SMS, Email, and Social Media Marketing across platforms like Facebook and Instagram. Voice Broadcast reaches less tech-savvy customers, offering technical assistance.",
    image: Images.religion_img_ten,
    benefit:
      "Increased adoption of digital banking, reduced reliance on physical branches, and improved customer convenience through mobile services.",
  },
];





