// import React, { useState } from "react";
// import Navbar from "components/Navbar";
// import { Link, useParams, useNavigate } from "react-router-dom";

// import {
//   Page,
//   Container,
//   Content,
//   ContentHeader,
//   HeaderTitle,
//   HeaderSubTitle,
// } from "../components/Styles/PageStyles";

// import Images from "Images";
// import tw from "tailwind-styled-components";
// import Sidebar from "components/SideNavbar";
// import TopNavbar from "components/TopNavbar";
// import DashboardHeader from "components/Dashboard/DashboardHeader";
// import StatisticsCards from "components/Dashboard/StatisticsChart";
// import TrafficChart from "components/Dashboard/TrafficChart";
// import RecentActivities from "components/Dashboard/RecentActivities";
// import OrderStatus from "components/Dashboard/OrderStatus";
// import StatCards from "components/Dashboard/StatCards";
// import Summary from "components/Dashboard/Summary";
// import Graph from "components/Dashboard/Graph";
// import {
//   FaCrown,
//   FaFacebook,
//   FaInstagram,
//   FaSms,
//   FaTelegram,
//   FaWhatsapp,
// } from "react-icons/fa";
// import { IoHeartCircleSharp, IoHeart } from "react-icons/io5";
// import { BsGraphUpArrow } from "react-icons/bs";
// import { FaWandMagicSparkles, FaChartBar } from "react-icons/fa6";
// import StatCard from "components/Dashboard/StatCard";
// import { MdEmail, MdGraphicEq, MdStackedBarChart } from "react-icons/md";
// import { SlGraph } from "react-icons/sl";
// import { ImParagraphLeft } from "react-icons/im";
// import { MdAutoGraph } from "react-icons/md";
// import { PiPlusCircleBold } from "react-icons/pi";
// import { useMutation, useQuery } from "react-query";
// import axios from "axios";
// import Config from "../Config";
// import { useSelector } from "react-redux";
// import { BiLogoTelegram } from "react-icons/bi";
// import Loading from "components/Loading";
// import NotFoundModel from "components/NotFoundModel";

// const Dashboard = () => {
//   const user = useSelector((state) => state.UserReducer.user);
//   const [openUploadModel, setOpenUploadModel] = useState(false);
//   const [gotInitialResp, setGotInitialResp] = useState(false);
//   const fetchFunction = async () =>
//     await axios.get(`${Config.apiUrl}/dashboard`, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Token: `${user.token}`,
//       },
//     });

//   const { isLoading, error, data, refetch, isRefetching } = useQuery(
//     "adminApi",
//     fetchFunction,
//     {
//       onSuccess: () => setGotInitialResp(true),
//       onError: () => setGotInitialResp(true),
//     }
//   );

//   return (
//     <>
//       <Container>
//         {/* <Sidebar /> */}
//         <Page>
//           <TopNavbar />
//           <Content>
//             <ContentHeader>
//               <div className="flex items-center justify-between">
//                 <HeaderTitle>Welcome, {user.name}</HeaderTitle>
//               </div>
//               <HeaderSubTitle>Manage your dashboard settings</HeaderSubTitle>
//             </ContentHeader>
//             {(isLoading || !gotInitialResp) && <Loading />}
//             {(error || !data) && !isLoading && gotInitialResp && (
//               <NotFoundModel />
//             )}
//             {!isLoading && gotInitialResp && (
//               <>
//                 <DashboardContainer>
//                   <MainContent>
//                     <div className="md:flex ">
//                       <Summary campaignData={data?.data?.data} />
//                       <Graph graphData={data?.data?.data} />
//                     </div>
//                     <div className="md:flex  grid grid-cols-2">
//                       <StatCard
//                         title="Facebook"
//                         value={data?.data?.data?.card[5]?.count}
//                         icon={FaFacebook}
//                         color="#667ADD"
//                       />
//                       <StatCard
//                         title="Instagram"
//                         value={data?.data?.data?.card[6]?.count}
//                         icon={FaInstagram}
//                         color="#e1306c"
//                       />
//                       <StatCard
//                         title="Whatsapp"
//                         value={data?.data?.data?.card[3]?.count}
//                         icon={FaWhatsapp}
//                         color="#4BA23C"
//                       />
//                       <StatCard
//                         title="Telegram"
//                         value={data?.data?.data?.card[4]?.count}
//                         icon={BiLogoTelegram}
//                         color="#667ADD"
//                       />
//                     </div>
//                   </MainContent>
//                   <SideContent>
//                     <TrafficChart cardData={data?.data?.data} />
//                   </SideContent>
//                 </DashboardContainer>
//                 <CardContainer>
//                   <StatCards
//                     title="SMS"
//                     value={data?.data?.data?.card[0]?.count}
//                     date="Jan 01 - Jan 10"
//                     color="#E0F7FA"
//                     icon={FaSms}
//                     iconcolor="#22c55e"
//                   />
//                   <StatCards
//                     title="Email"
//                     value={data?.data?.data?.card[1]?.count}
//                     date="Jan 01 - Jan 10"
//                     color="#FFFDE7"
//                     icon={MdEmail}
//                     iconcolor="#FFC700"
//                   />
//                   <StatCards
//                     title="Voice"
//                     value={data?.data?.data?.card[2]?.count}
//                     date="Jan 01 - Jan 10"
//                     color="#FBE9E7"
//                     icon={MdGraphicEq}
//                     iconcolor="#FF834B"
//                   />
//                 </CardContainer>
//                 <div className=" bg-gray-100 p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
//                   <div className="w-full md:w-1/3">
//                     <RecentActivities
//                       ActivityData={data?.data?.data.socialMedia || []}
//                     />
//                   </div>
//                   <div className="w-full md:w-2/3">
//                     <OrderStatus ApiData={data?.data?.data} />
//                   </div>
//                 </div>
//               </>
//             )}
//           </Content>
//         </Page>
//       </Container>
//     </>
//   );
// };

// const DashboardContainer = tw.div`flex flex-wrap md:flex-nowrap`;
// const CardContainer = tw.div`flex flex-wrap md:flex-nowrap `;

// const Button = tw.button`text-white bg-orange-500 hover:bg-orange-600 px-8 flex items-center space-x-1 justify-center h-10 text-base whitespace-nowrap rounded`;

// const MainContent = tw.div`w-full md:w-[70%] p-2 `;
// const SideContent = tw.div`w-full md:w-[30%] p-2 `;
// export default Dashboard;

import React, { useState } from "react";
import Navbar from "components/Navbar";
import { Link, useParams, useNavigate } from "react-router-dom";

import {
  Page,
  Container,
  Content,
  ContentHeader,
  HeaderTitle,
  HeaderSubTitle,
} from "../components/Styles/PageStyles";

import Images from "Images";
import tw from "tailwind-styled-components";
import Sidebar from "components/SideNavbar";
import TopNavbar from "components/TopNavbar";
import DashboardHeader from "components/Dashboard/DashboardHeader";
import StatisticsCards from "components/Dashboard/StatisticsChart";
import TrafficChart from "components/Dashboard/TrafficChart";
import RecentActivities from "components/Dashboard/RecentActivities";
import OrderStatus from "components/Dashboard/OrderStatus";
import StatCards from "components/Dashboard/StatCards";
import Summary from "components/Dashboard/Summary";
import Graph from "components/Dashboard/Graph";
import { FaCrown } from "react-icons/fa";
import { IoHeartCircleSharp, IoHeart } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaWandMagicSparkles, FaChartBar } from "react-icons/fa6";
import StatCard from "components/Dashboard/StatCard";
import { MdGraphicEq, MdStackedBarChart } from "react-icons/md";
import { SlGraph } from "react-icons/sl";
import { ImParagraphLeft } from "react-icons/im";
import { MdAutoGraph } from "react-icons/md";
import { PiPlusCircleBold } from "react-icons/pi";

const Dashboard = () => {
  const [openUploadModel, setOpenUploadModel] = useState(false);

  return (
    <>
      <Container>
        {/* <Sidebar /> */}
        <Page>
          <TopNavbar />
          <Content>
            <ContentHeader>
              <div className="flex items-center justify-between">
                <HeaderTitle>Welcome, User</HeaderTitle>
                {/* <Button
                    className="rounded-xl"
                    type="button"
                    onClick={() => setOpenUploadModel(true)}
                  >
                    <PiPlusCircleBold size={18} />{" "}
                    <p className="font-semibold">Create</p>
                  </Button> */}
              </div>
              <HeaderSubTitle>Manage your dashboard settings</HeaderSubTitle>
              {/* <div className="flex gap-4 items-center mt-4">
                  <Link to="" className="flex items-center gap-1">
                    <p className="underline hover:text-topBar-purple">
                      Get started with contact groups
                    </p>
                    <img src={Images.ShareIcon} alt="Share" />
                  </Link>
                  <Link to="">
                    <p className="underline hover:text-topBar-purple">
                      Lists vs Segments
                    </p>
                  </Link>
                </div> */}
            </ContentHeader>

            <DashboardContainer>
              <MainContent>
                <div className="md:flex ">
                  <Summary />
                  <Graph />
                </div>
                <div className="md:flex  grid grid-cols-2">
                  <StatCard
                    title="Wallet Balance"
                    value="₵4,567.53"
                    icon={FaCrown}
                    color="#F23924"
                  />
                  <StatCard
                    title="Referral Earning"
                    value="₵1,689.53"
                    icon={IoHeart}
                    color="#667ADD"
                  />
                  <StatCard
                    title="Estimate Sales"
                    value="₵2,851.53"
                    icon={FaWandMagicSparkles}
                    color="#4BA23C"
                  />
                  <StatCard
                    title="Earning"
                    value="₵52,567.53"
                    icon={BsGraphUpArrow}
                    color="#FB7397"
                  />
                </div>
              </MainContent>
              <SideContent>
                <TrafficChart />
              </SideContent>
            </DashboardContainer>
            <CardContainer>
              <StatCards
                title="Revenue Status"
                value="₵432"
                date="Jan 01 - Jan 10"
                color="#E0F7FA"
                icon={MdGraphicEq}
                iconcolor="#22c55e"
              />
              <StatCards
                title="Page View"
                value="₵432"
                date="Jan 01 - Jan 10"
                color="#FFFDE7"
                icon={SlGraph}
                iconcolor="#FFC700"
              />
              <StatCards
                title="Bounce Rate"
                value="₵432"
                date="Jan 01 - Jan 10"
                color="#FBE9E7"
                icon={ImParagraphLeft}
                iconcolor="#FF834B"
              />
              <StatCards
                title="Revenue Status"
                value="₵432"
                date="Jan 01 - Jan 10"
                color="#EDE7F6"
                icon={MdAutoGraph}
                iconcolor="#9315FF"
              />
            </CardContainer>
            <div className=" bg-gray-100 p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/3">
                <RecentActivities />
              </div>
              <div className="w-full md:w-2/3">
                <OrderStatus />
              </div>
            </div>
          </Content>
        </Page>
      </Container>
    </>
  );
};

const DashboardContainer = tw.div`flex flex-wrap md:flex-nowrap`;
const CardContainer = tw.div`flex flex-wrap md:flex-nowrap `;

const Button = tw.button`text-white bg-orange-500 hover:bg-orange-600 px-8 flex items-center space-x-1 justify-center h-10 text-base whitespace-nowrap rounded`;

const MainContent = tw.div`w-full md:w-[75%] p-2 `;
const SideContent = tw.div`w-full md:w-[25%] p-2 `;
export default Dashboard;
