


// import React from 'react';
// import { FaFacebook, FaInstagram } from 'react-icons/fa';
// import tw from 'tailwind-styled-components';
// import moment from 'moment';
// import NotFoundModel from 'components/NotFoundModel';

// const ActivityItem = tw.div`flex items-start mb-4`;
// const ActivityTime = tw.span`text-gray-500 text-xs w-24`;
// const ActivityContent = tw.div`flex items-center space-x-4`;
// const ActivityIcon = tw.div`flex-shrink-0 text-2xl`;
// const ActivityDetails = tw.div``;
// const ActivityTitle = tw.h4`text-sm font-medium text-gray-700`;
// const ActivityDescription = tw.p`text-xs text-gray-500`;

// const RecentActivities = ({ ActivityData }) => {

//     return (
//         <div className="bg-white p-4 shadow-md rounded-md h-[354px] overflow-y-auto">
//             <h2 className="text-lg font-bold mb-4">Recent Activities</h2>
//             {ActivityData.map((activity, index) => {
//                 const icon = activity.post_type === "1" ? <FaFacebook className="text-blue-500" /> : <FaInstagram className="text-pink-500" />;
//                 const timeAgo = moment(activity.created_at).fromNow();

//                 return (
//                     <ActivityItem key={index}>
//                         <ActivityTime>{timeAgo}</ActivityTime>
//                         <ActivityContent>
//                             <ActivityIcon>{icon}</ActivityIcon>
//                             <ActivityDetails>
//                                 <ActivityTitle>{activity.socialname}</ActivityTitle>
//                                 <ActivityDescription>{activity.text}</ActivityDescription>
//                             </ActivityDetails>
//                         </ActivityContent>

//                     </ActivityItem>
//                 );
//             })}
//         </div>
//     );
// };

// export default RecentActivities;

// src/components/RecentActivities.jsx
import React from 'react';
import { FaTasks, FaHandshake, FaFileAlt, FaCogs, FaComment } from 'react-icons/fa';
import tw from 'tailwind-styled-components';

const activities = [
    { time: '42 Mins Ago', icon: <FaTasks className="text-blue-500" />, title: 'Task Updated', description: 'Nikolai Updated a Task' },
    { time: '1 day Ago', icon: <FaHandshake className="text-pink-500" />, title: 'Deal Added', description: 'Panshi Added a Task' },
    { time: '42 Mins Ago', icon: <FaFileAlt className="text-blue-500" />, title: 'Published Article', description: 'Rasel Published a Article' },
    { time: '1 day Ago', icon: <FaCogs className="text-yellow-500" />, title: 'Dock Updated', description: 'Reshmi Updated a Dock' },
    { time: '1 day Ago', icon: <FaComment className="text-green-500" />, title: 'Replied Comment', description: 'Janthon Added a Comment' },
];

const ActivityItem = tw.div`flex items-start mb-4`;
const ActivityTime = tw.span`text-gray-500 text-xs w-24`;
const ActivityContent = tw.div`flex items-center space-x-4`;
const ActivityIcon = tw.div`flex-shrink-0`;
const ActivityDetails = tw.div``;
const ActivityTitle = tw.h4`text-sm font-medium text-gray-700`;
const ActivityDescription = tw.p`text-xs text-gray-500`;

const RecentActivities = () => {
    return (
        <div className="bg-white p-4 shadow-md rounded-md">
            <h2 className="text-lg font-bold mb-4">Recent Activities</h2>
            {activities.map((activity, index) => (
                <ActivityItem key={index}>
                    <ActivityTime>{activity.time}</ActivityTime>
                    <ActivityContent>
                        <ActivityIcon>{activity.icon}</ActivityIcon>
                        <ActivityDetails>
                            <ActivityTitle>{activity.title}</ActivityTitle>
                            <ActivityDescription>{activity.description}</ActivityDescription>
                        </ActivityDetails>
                    </ActivityContent>
                </ActivityItem>
            ))}
        </div>
    );
};

export default RecentActivities;
