import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import tw from "tailwind-styled-components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Page,
  Container,
  Content,
  ContentHeader,
  HeaderTitle,
  HeaderSubTitle,
} from "components/Styles/PageStyles";
import {
  InputGroup,
  FieldWrappers,
  Label,
  SubmitBtn,
  FieldWrapper,
  CancelBtn,
} from "components/Styles/InputStyles";
import TopNavbar from "components/TopNavbar";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuery, useMutation } from "react-query";
import Config from "../../Config";
import { toast } from "react-toastify";
import CreateSurvey from "components/UssdSurveyBuilder/CreateSurvey";
import { RiSurveyLine } from "react-icons/ri";

// Validation schemas
const SurveySchemaStep1 = Yup.object().shape({
  surveyTitle: Yup.string().required("A Survey Title is required"),
  shortCode: Yup.string().required("You must select a short Code"),
});

function useQueryHook() {
  return new URLSearchParams(useLocation().search);
}

// Main component
const UssdSurveyCreation = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [gotInitialResp, setGotInitialResp] = useState(false);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openTestModel, setOpenTestModel] = useState(false);
  const [type, setType] = useState(4);
  const [initialValues, setInitialValues] = useState({
    surveyTitle: "",
    shortCode: "",
    surveyData: "",
    rawData: "",
  });
  const [selectedSurveyTitle, setSelectedSurveyTitle] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedShortCode, setSelectedShortCode] = useState(false);

  //Todo => Handle redirect after a callback
  const query = useQueryHook();
  const key = query.get("key");

  const fetchFunction = async (type) =>
    await axios.get(`${Config.apiUrl}/getAllListing/${type}`, {
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

  const { isLoading, error, data, refetch } = useQuery(
    ["getContactGroups", type],
    () => fetchFunction(type),
    {
      onSuccess: () => {
        setGotInitialResp(true);
      },
      onError: (error) => {
        setGotInitialResp(true);
      },
    }
  );
  useEffect(() => {});

  //------- Add Schedule Model -------
  const ScheduleFunction = async (values) => {
    return await axios.post(`${Config.apiUrl}/campaign/email/create`, values, {
      headers: {
        "Content-Type": "multipart/form-data",
        Token: `${user.token}`,
      },
    });
  };

  const scheduleSuccess = (data) => {
    navigate("/email-campaign");
    setOpenUploadModel(false);
    toast.success(data?.data?.msg || "Success");
  };

  const scheduleError = (data) => {
    setOpenUploadModel(false);
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: scheduleLoading, mutate: ScheduleMutate } = useMutation(
    ScheduleFunction,
    {
      onSuccess: scheduleSuccess,
      onError: scheduleError,
    }
  );

  //------- Add Send Test Model -------
  const SendTestFunction = async (values) => {
    return await axios.post(`${Config.apiUrl}/campaign/email/test`, values, {
      headers: {
        "Content-Type": "multipart/form-data",
        Token: `${user.token}`,
      },
    });
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    if (step === 1) {
      setInitialValues(values);
      setSelectedSurveyTitle(values.surveyTitle);
      setStep(2);
    } else {
      setInitialValues(values);

      console.log("Initial Values:", values);
    }
  };
  const handleSave = (values) => {
    setInitialValues(values);
    setExpanded(false);
    console.log("Initial Values:", values);
  };

  //Todo => Cancel The process
  const cancelSurveyCreation = () => {
    navigate("/ussd-survey");
  };

  return (
    <Container>
      <Page>
        <TopNavbar />
        <div
          className={`${
            openUploadModel ? "flex" : "hidden"
          } h-screen w-screen bg-black/30 backdrop-blur-sm  fixed inset-0 transition-all duration-400 ease-in-out z-50`}
        />
        {step === 1 && (
          <Contents>
            <ContentHeader>
              <HeaderTitle>Create USSD Survey</HeaderTitle>
              <HeaderSubTitle className="mt-6">
                Engage your audience with a quick USSD survey. Gather valuable
                feedback effortlessly!
              </HeaderSubTitle>
            </ContentHeader>

            <Formik
              initialValues={initialValues}
              validationSchema={SurveySchemaStep1}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="surveyTitle">
                        How will you name your survey?
                      </Label>
                      <FieldWrappers>
                        <Field
                          type="text"
                          name="surveyTitle"
                          id="surveyTitle"
                          placeholder="Type the name of your survey"
                          autoComplete="off"
                          required
                          maxLength="60"
                          onChange={(e) => {
                            setFieldValue("surveyTitle", e.target.value);
                            setTitleCharCount(e.target.value.length);
                          }}
                          value={values.surveyTitle}
                        />
                        <MediaCharCount>{titleCharCount}/60</MediaCharCount>
                      </FieldWrappers>
                      {errors.surveyTitle && touched.surveyTitle ? (
                        <Error>{errors.surveyTitle}</Error>
                      ) : null}
                    </InputGroup>
                  </Wrapper>
                  <Wrapper>
                  <InputGroup>
                      <Label htmlFor="shortCode">Select Shortcode *</Label>
                      <FieldWrapper $select={true}>
                        <Field
                          required
                          name="shortCode"
                          id="shortCode"
                          autoComplete="off"
                        >
                          {(props) => (
                            <Select
                              required
                              {...props.field}
                              className="w-full h-full pl-2 bg-transparent text-sm p-0 border-none focus:border-orange-500"
                              autoComplete="none"
                            >
                              <MenuItem value="0" disabled>
                                Select Short Code
                              </MenuItem>
                              <MenuItem value={"*380*800#"}>*380*800#</MenuItem>
                              <MenuItem value={"*380*800#"}>*380*700#</MenuItem>
                            </Select>
                          )}
                        </Field>
                      </FieldWrapper>
                      {errors.shortCode && touched.shortCode ? (
                        <Error>{errors.shortCode}</Error>
                      ) : null}
                    </InputGroup>
                  </Wrapper>
                  <div className="flex justify-end mt-8">
                    <CancelBtn
                      className="mr-4"
                      type="button"
                      onClick={cancelSurveyCreation}
                    >
                      Cancel
                    </CancelBtn>
                    <BtnWrapper>
                      <SubmitBtn type="submit">Next</SubmitBtn>
                    </BtnWrapper>
                  </div>
                </Form>
              )}
            </Formik>
          </Contents>
        )}
        {step === 2 && data && <Content>
          <ContentHeader>
            <HeaderTitle>
              <div className="flex items-center gap">
              <RiSurveyLine className="mr-2 text-orange-500" />
              {selectedSurveyTitle}
              </div>
            </HeaderTitle>
          </ContentHeader>
          <CreateSurvey setStep={setStep} />
          </Content>}
      </Page>
    </Container>
  );
};

const Contents = tw.section`py-10 px-8 max-w-2xl mx-auto `;
const Wrapper = tw.div`grid  my-6 `;
const Error = tw.div`text-red-500 text-xs italic mb-4`;
const BtnWrapper = tw.div`flex justify-center items-center `;
const MediaCharCount = tw.div`absolute top-2.5 right-2 text-xs text-gray-400`;
export default UssdSurveyCreation;
