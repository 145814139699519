import Images from "Images";

export const benefits = [
  {
    title: "Personalized Customer Engagement",
    description:
      "The integration of AI-powered content and multi-channel communication allows for highly personalized customer interactions, improving engagement and retention.",
    image: Images.outreach,
  },
  {
    title: "Efficient Lead Generation",
    description:
      "By utilizing geo-location targeting, social media ads, and lead generation tools like the USSD Menu, insurance companies can efficiently acquire new customers.",
    image: Images.leadGeneration,
  },
  {
    title: "Increased Policy Renewals",
    description:
      "Automation of renewal reminders via multiple channels (SMS, email, voice) reduces the risk of lapsed policies and improves customer loyalty.",
    image: Images.policyRenewal,
  },
  {
    title: "Enhanced Customer Experience",
    description:
      "The seamless integration of omni-channel engagement, surveys, and feedback tools allows insurance companies to continuously improve the customer experience.",
    image: Images.enhancement,
  },
  {
    title: "Time-Saving Automation",
    description:
      "The automation of marketing, appointment scheduling, and claims processing streamlines operations, allowing insurance companies to serve customers more efficiently.",
    image: Images.timeSaving,
  },
  {
    title: "Data-Driven Decision Making",
    description:
      "Detailed reporting and analytics provide actionable insights, enabling insurance companies to refine campaigns, improve products, and offer better services based on customer feedback.",
    image: Images.dataDriven,
  },
];


export const features = [
  {
    title: "Health and Auto Insurance Enrollment and Renewal Campaigns",
    useCase:
      "Increase enrollments and renewals for health and auto insurance policies through targeted campaigns.",
    mechanics:
      "Insurance companies use Bulk SMS, Email Campaigns, and Social Media Ads to promote enrollment periods for health insurance and send personalized renewal reminders for auto insurance. AI Content Creation generates tailored messages to improve engagement.",
    image: Images.religion_img_one,
    benefit:
      "Increased enrollments and renewals, reduced policy lapses, and improved customer retention through personalized messaging and targeted campaigns.",
  },
  {
    title: "Life Insurance Upsell and Personalized Campaigns",
    useCase:
      "Promote higher-value life insurance products and personalized packages based on customer demographics.",
    mechanics:
      "Utilize Sales & Marketing Automation to identify eligible customers for upsell opportunities. AI-generated content delivers personalized offers via Bulk Email, SMS, and Voice Broadcast messages to engage customers based on age, income, and location.",
    image: Images.religion_img_two,
    benefit:
      "Increased revenue from upsell opportunities, better customer engagement with personalized marketing, and higher sales through targeted outreach.",
  },
  {
    title: "Insurance Lead Generation and Engagement Campaigns",
    useCase:
      "Generate leads and engage potential customers interested in various insurance products.",
    mechanics:
      "Run Lead Generation Campaigns using USSD Menus, Bulk SMS, and targeted Social Media Ads. Capture leads for follow-up using automated workflows and segment audiences for efficient customer acquisition.",
    image: Images.religion_img_three,
    benefit:
      "Increased lead generation, efficient customer targeting, and higher acquisition rates for new customers.",
  },
  {
    title: "Customer Satisfaction Surveys and Feedback for Claims Process",
    useCase:
      "Improve the customer experience by collecting feedback after the claims process.",
    mechanics:
      "Deploy Surveys via SMS and Email after a claim is completed. The Polling and Feedback Management Module collects data for analysis to enhance service quality.",
    image: Images.religion_img_four,
    benefit:
      "Improved customer service, actionable insights for process improvements, and enhanced customer satisfaction and retention.",
  },
  {
    title: "New Insurance Product Launch and Promotion",
    useCase:
      "Promote new insurance products effectively to specific customer segments.",
    mechanics:
      "Implement Multi-Channel Campaigns using Email, SMS, and Social Media Marketing on platforms like Facebook and Instagram. Geo-Location Targeting reaches specific regions and demographics for product promotion.",
    image: Images.religion_img_five,
    benefit:
      "Increased awareness and adoption of new products, targeted reach, and broader coverage across multiple channels.",
  },
  {
    title: "Automated Claims Assistance and Support via USSD",
    useCase:
      "Streamline the claims process and provide efficient support to customers.",
    mechanics:
      "Use the Drag & Drop USSD Menu Builder for customers to file or check the status of claims. Bulk SMS provides updates, and AI Content ensures a user-friendly experience.",
    image: Images.religion_img_six,
    benefit:
      "Improved claims processing efficiency, enhanced customer convenience, and reduced operational costs.",
  },
  {
    title: "Social Media Campaigns for Insurance Discounts and Engagement",
    useCase:
      "Increase visibility and engagement for insurance products through social media promotions.",
    mechanics:
      "Run Social Media Campaigns on Facebook, Instagram, TikTok, and LinkedIn to promote discounts and offers. Post Scheduling and AI-generated content ensure consistent and engaging messaging.",
    image: Images.religion_img_seven,
    benefit:
      "Higher customer engagement, increased visibility for promotional offers, and better lead generation through social platforms.",
  },
  {
    title: "Omnichannel Customer Support and Communication",
    useCase:
      "Provide seamless customer support across multiple channels to resolve queries and issues.",
    mechanics:
      "Utilize the Omni-Channel Engagement feature to offer support through SMS, Email, Social Media, and Voice Broadcast. AI tools provide automated responses to FAQs for faster service.",
    image: Images.religion_img_eight,
    benefit:
      "Improved customer support experience, faster resolution of issues, and better customer satisfaction.",
  },
  {
    title: "Travel Insurance Promotion During Peak Seasons",
    useCase:
      "Boost travel insurance sales during holidays and peak travel periods.",
    mechanics:
      "Use Geo-Location Campaigning to target customers planning trips. Run campaigns via Bulk SMS, Email, and Social Media Ads on Facebook and LinkedIn, focusing on travel coverage benefits.",
    image: Images.religion_img_nine,
    benefit:
      "Increased travel insurance purchases, better customer targeting, and enhanced brand visibility.",
  },
  {
    title: "Loyalty Rewards and Retention Programs",
    useCase:
      "Enhance customer loyalty and retention through reward programs for long-term customers.",
    mechanics:
      "Implement the Loyalty Management Campaign Module to reward customers with points or discounts. Communicate via Bulk SMS and Email, and use the USSD Menu for easy redemption.",
    image: Images.religion_img_ten,
    benefit:
      "Improved customer retention, stronger loyalty from long-term customers, and increased satisfaction through rewards.",
  },
];





