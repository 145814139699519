import React from "react";
import { Link } from "react-router-dom";
import { features } from "./data";
import { IoSettingsOutline } from "react-icons/io5";
import { GiProfit } from "react-icons/gi";
import GetStarted from "./GetStarted";

const Features = () => {

  const firstFiveFeatures = features.slice(0, 5);
  const lastFiveFeatures = features.slice(5, features.length);
  return (
    <>
    <div className="relative overflow-hidden bg-white">
      <div className="pb-80 pt-8 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-10">
        {firstFiveFeatures.map((feature, index) => {
          const isOdd = index % 2 !== 0;
          
          return (
            <div
              key={index}
              className="relative mx-auto Container max-w-6xl py-12 lg:py-12"
            >
              <div className={`flex flex-col ${isOdd ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center justify-between`}>
                <div className="sm:max-w-lg lg:w-1/2 px-4">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {feature.title}
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 w-full">{feature.useCase}</p>
                  <div className="flex flex-col gap-4 mt-8">
                    <div className="flex items-center gap-3 h-fit">
                      <div className="p-6 flex items-center justify-center rounded-md border border-gray-400">
                        <IoSettingsOutline className="text-3xl text-gray-600" />
                      </div>
                      <div className="flex-col flex mb-3">
                        <p className="text-lg font-bold text-gray-600">
                          Mechanics
                        </p>
                        <p className="text-gray-500 text-md font-medium">
                          {feature.mechanics}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 mt-4 w-full">
                    <div className="flex items-center gap-3">
                      <div className="p-6 flex items-center justify-center rounded-md border border-gray-400">
                        <GiProfit className="text-3xl text-gray-600" />
                      </div>
                      <div className="flex-col flex mb-3">
                        <p className="text-lg font-bold text-gray-600">
                          Benefit
                        </p>
                        <p className="text-gray-500 text-md font-medium">
                          {feature.benefit}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    className="mt-6 inline-block rounded-md border border-transparent bg-orange-600 px-8 py-3 text-center font-medium text-white hover:bg-black-900"
                  >
                    Sign up Free
                  </Link>
                </div>

                <div className={`mt-10 lg:w-1/2 px-4 relative`}>
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    <GetStarted/>
    <div className="relative overflow-hidden bg-white">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        {lastFiveFeatures.map((feature, index) => {
          const isOdd = index % 2 !== 0;
          
          return (
            <div
              key={index}
              className="relative mx-auto Container max-w-6xl py-12 lg:py-12"
            >
              <div className={`flex flex-col ${isOdd ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center justify-between`}>
                <div className="sm:max-w-lg lg:w-1/2 px-4">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {feature.title}
                  </h1>
                  <p className="mt-4 text-xl text-gray-500 w-full">{feature.useCase}</p>
                  <div className="flex flex-col gap-4 mt-8">
                    <div className="flex items-center gap-3 h-fit">
                      <div className="p-6 flex items-center justify-center rounded-md border border-gray-400">
                        <IoSettingsOutline className="text-3xl text-gray-600" />
                      </div>
                      <div className="flex-col flex mb-3">
                        <p className="text-lg font-bold text-gray-600">
                          Mechanics
                        </p>
                        <p className="text-gray-500 text-md font-medium">
                          {feature.mechanics}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 mt-4 w-full">
                    <div className="flex items-center gap-3">
                      <div className="p-6 flex items-center justify-center rounded-md border border-gray-400">
                        <GiProfit className="text-3xl text-gray-600" />
                      </div>
                      <div className="flex-col flex mb-3">
                        <p className="text-lg font-bold text-gray-600">
                          Benefit
                        </p>
                        <p className="text-gray-500 text-md font-medium">
                          {feature.benefit}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    className="mt-6 inline-block rounded-md border border-transparent bg-orange-600 px-8 py-3 text-center font-medium text-white hover:bg-black-900"
                  >
                    Sign up Free
                  </Link>
                </div>

                {/* Image Grid */}
                <div className={`mt-10 lg:w-1/2 px-4 relative`}>
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>

    </>
  );
};

export default Features;
