import { SiAltiumdesigner, SiMarketo } from "react-icons/si";
import {
  MdCorporateFare,
  MdOutlineCampaign,
  MdOutlinePersonalInjury,
  MdSell,
} from "react-icons/md";
import { IoBriefcaseSharp } from "react-icons/io5";
import {
  FaAcquisitionsIncorporated,
  FaChartLine,
  FaHeartbeat,
  FaHome,
  FaHotel,
  FaIndustry,
  FaShoppingCart,
} from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";

import { GiDeliveryDrone } from "react-icons/gi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrOverview } from "react-icons/gr";
import { PiBroadcastFill } from "react-icons/pi";
import Images from "../Images";
export const data = {
  benefits: [
    {
      heading: "Increased Efficiency",
      subHeading:
        "Automates repetitive tasks, reducing manual effort and freeing up time for strategic activities.",
      icon: <FaAcquisitionsIncorporated />,
    },
    {
      heading: "Improved Lead Management",
      subHeading:
        "Streamlines lead nurturing and follow-up processes, boosting conversion rates and enhancing sales outcomes.",
      icon: <GiDeliveryDrone />,
    },
    {
      heading: "Enhanced Customer Engagement",
      subHeading:
        "Delivers personalized and timely communication, improving customer experience and satisfaction.",
      icon: <MdOutlinePersonalInjury />,
    },
    {
      heading: "Consistent Campaign Execution",
      subHeading:
        "Ensures marketing campaigns are executed consistently across channels, maximizing reach and impact.",
      icon: <MdOutlineCampaign />,
    },
    {
      heading: "Data-Driven Decisions",
      subHeading:
        "Provides detailed analytics and insights for informed decision-making and strategy refinement.",
      icon: <FaMoneyBillTransfer />,
    },
    {
      heading: "Optimized Resource Allocation",
      subHeading:
        "Identifies high-performing campaigns and channels, enabling more effective resource allocation.",
      icon: <SiAltiumdesigner />,
    },
    {
      heading: "Scalability",
      subHeading:
        "Supports scaling of marketing and sales efforts without increasing manual workload, simplifying growth management.",
      icon: <FaChartLine />,
    },
  ],
};

export const cards = {
  features: [
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Lead Management",
      title: "Lead Scoring",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Automatically assigns scores to leads based on criteria like
            engagement level, demographic info, and interaction history.
            Prioritize leads for better conversion rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "High-Potential Focus",
              description:
                "Ensure timely follow-ups with high-potential leads to improve conversion rates.",
            },
          ],
        },
        {
          emoji: Images.settings,
          title: "Functionality",
          description: [
            {
              topHeading: "Automatic Assignment",
              description:
                "Automatically assigns scores to leads based on predefined criteria such as engagement level, demographic information, and interaction history. Scores help prioritize leads.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Lead Management",
      title: "Lead Segmentation",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Categorize leads based on behavior, source, and demographics for
            targeted messaging and personalized strategies.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Personalized Communication",
              description:
                "Increase relevance and engagement with tailored marketing and Use segmentation to enhance campaign effectiveness.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Leads Categorization",
              description:
                "Categorize leads into different segments based on criteria such as behavior, source, and demographics. Allows for targeted messaging.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Lead Management",
      title: "Automated Lead Nurturing",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Automate email, SMS, and messaging workflows based on lead behavior
            to nurture them through the sales funnel efficiently.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Continuous Engagement",
              description:
                "Keeps leads engaged and moves them through the sales funnel efficiently without manual intervention.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Continuous Engagement",
              description:
                "Send automated emails, SMS, or other messages to nurture leads through predefined workflows based on their behavior and interactions.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Campaign Automation",
      title: "Drip Campaigns",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Set up automated, sequential messaging campaigns that deliver
            content at specific intervals or based on user actions.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Consistent Communication",
              description:
                "Ensure consistent communication with leads and customers, enhancing engagement and nurturing over time.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Automated Messaging",
              description:
                "Set up automated, sequential messaging campaigns that deliver content at specific intervals or based on user actions",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Campaign Automation",
      title: "Triggered Campaigns",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Automatically initiate campaigns based on specific triggers like
            user actions or events for timely and relevant communication.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Timely Communication",
              description:
                "Provides timely and relevant communication that can boost conversion rates and reduce cart abandonment.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "User Action Triggers",
              description:
                "Automatically initiates campaigns based on specific triggers such as user actions (e.g., form submissions, website visits) or events (e.g., abandoned cart).",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Campaign Automation",
      title: "Multi-Channel Campaigns",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Automate campaigns across email, SMS, social media, and voice,
            ensuring a cohesive strategy across all touchpoints.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Increased Reach",
              description:
                "Increases reach and effectiveness by engaging customers through their preferred channels.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Automate Campaigns",
              description:
                "Automates campaigns across various channels including email, SMS, social media, and voice. Ensures a cohesive strategy across touchpoints.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "CRM Integration",
      title: "Contact Management",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Centralize contact information and interactions for a unified view
            of customer data and history, enhancing relationship management.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Comprehensive Insights",
              description:
                "Enhances relationship management by providing comprehensive insights into customer interactions and preferences.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Centralizes Contact",
              description:
                "Centralizes contact information and interactions, allowing for a unified view of customer data and history.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "CRM Integration",
      title: "Sales Pipeline Management",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Track and manage leads through various stages of the sales pipeline,
            providing visibility into sales progress.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Pipeline Visibility",
              description:
                "Provides visibility into sales progress, helping sales teams focus on high-priority deals and manage follow-ups effectively.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Tracking and Managing Leads",
              description:
                "Tracks and manages leads through various stages of the sales pipeline, from initial contact to final conversion.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "CRM Integration",
      title: "Activity Tracking",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Monitor customer interactions and activities like email opens,
            clicks, and website visits to gain insights into customer behavior.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Behavioral Insights",
              description:
                "Offers insights into customer behavior and engagement, enabling more personalized and targeted follow-ups.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Monitors Customer Interactions",
              description:
                "Monitors customer interactions and activities, including email opens, clicks, and website visits.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Personalized Email Campaigns",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Create and send personalized email campaigns based on customer data,
            behavior, and preferences to increase open and click-through rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Increased Engagement",
              description:
                "Increases open and click-through rates by delivering relevant content to recipients.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Personalized Email Campaigns",
              description:
                "Creates and sends personalized email campaigns based on customer data, behavior, and preferences.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Automated Follow-Ups",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Set up automated follow-up emails based on customer interactions,
            ensuring timely responses and continued engagement.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Timely Follow-Ups",
              description:
                "Ensures timely responses and ongoing engagement without manual effort.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Testing Email Campaigns",
              description:
                "Automatically sends follow-up emails based on user actions or time intervals.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "A/B Testing",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Optimized Campaigns",
              description:
                "Optimizes email campaigns by identifying the most effective elements.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Optimized Campaigns",
              description:
                "Tests different versions of email content, subject lines, or layouts to determine which performs best.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Campaign Performance Reports",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Insights",
              description:
                "Provides insights into the effectiveness of campaigns and helps identify areas for improvement.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Report Generation",
              description:
                "Generates detailed reports on campaign performance, including metrics such as open rates, click-through rates, conversion rates, and ROI.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Lead and Customer Insights",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Insights",
              description:
                "Helps in refining marketing strategies and improving lead nurturing efforts.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Report Generation",
              description:
                "Analyzes lead and customer data to provide insights into behavior, engagement, and purchasing patterns.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Sales Performance Reports",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Visibility Offer",
              description:
                "Offers visibility into sales performance and helps in forecasting and strategy adjustments.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Sales Metrics",
              description:
                "Tracks sales metrics such as closed deals, revenue, and sales cycle length.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Scheduled Posts",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Visibility Offer",
              description:
                "Ensures consistent social media presence and engagement without manual posting.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Scheduled Posts",
              description:
                "Allows users to schedule and automate social media posts across various platforms.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Social Media Monitoring",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Social Media Performance",
              description:
                "Provides insights into social media performance and audience sentiment.",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Tracking Interactions",
              description:
                "Tracks social media interactions, mentions, and engagement metrics.",
            },
          ],
        },
      ],
    },
    {
      videoSrc: "https://www.youtube.com/watch?v=zcE14WpxIHI",
      subtitle: "Email Marketing Automation",
      title: "Automated Responses",
      learn: "Signup Free",
      url: "/register",
      overview: {
        icon: <GrOverview />,
        title: "Overview",
        description: (
          <>
            Test different email variations to optimize subject lines, content,
            and send times, ensuring the highest engagement rates.
          </>
        ),
      },
      properties: [
        {
          icon: <IoBriefcaseSharp />,
          title: "Benefits",
          emoji: Images.benefit,
          description: [
            {
              topHeading: "Customer Engagement",
              description:
                ": Enhances customer service and engagement by providing timely responses",
            },
          ],
        },
        {
          icon: <IoBriefcaseSharp />,
          title: "Functionality",
          emoji: Images.settings,
          description: [
            {
              topHeading: "Response Automation",
              description:
                "Automates responses to common social media inquiries or interactions.",
            },
          ],
        },
      ],
    },
  ],
};

export const useCases = {
  cases: [
    {
      title: "E-Commerce Industry",
      icon: <FaShoppingCart />,
      sub_heading:
        "Increased conversion rates, improved customer retention, and enhanced understanding of customer behavior and preferences through targeted follow-ups, personalized content, and automated, relevant communication.",
      topHeading: "Automated Lead Nurturing and Conversion",
      scenario:
        "An online retailer wants to increase sales and improve customer retention by automating their marketing and sales processes.",
      implementations: [
        {
          title: "Lead Management & Nurturing",
          description:
            "Implement lead scoring to prioritize leads based on their browsing behavior and past purchases. Use automated email and SMS campaigns to follow up with leads who have shown interest but haven’t completed a purchase.",
        },
        {
          title: "Campaign Automation",
          description:
            "Set up drip campaigns that send a series of personalized emails to new subscribers, offering discounts, product recommendations, and educational content.",
        },
        {
          title: "CRM Integration",
          description:
            "Track customer interactions and segment audiences based on their purchase history and preferences.",
        },
        {
          title: "Email Marketing Automation",
          description:
            "Automate abandoned cart reminders, personalized product recommendations, and post-purchase follow-ups.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Monitor conversion rates, track the effectiveness of different campaigns, and analyze customer behavior to refine marketing strategies.",
        },
      ],
      benefits: [
        {
          item: "Increased conversion rates through targeted follow-ups and personalized content.",
        },
        {
          item: "Improved customer retention with automated, relevant communication.",
        },
        {
          item: "Enhanced understanding of customer behavior and preferences.",
        },
      ],
    },
    {
      title: "Healthcare Industry",
      icon: <FaHeartbeat />,
      sub_heading:
        "Reduced appointment no-shows, improved patient adherence to care plans, and streamlined appointment management through enhanced patient engagement with personalized and timely communication.",
      topHeading: "Patient Engagement and Appointment Management",
      scenario:
        "A healthcare provider wants to enhance patient engagement and streamline appointment scheduling.",
      implementations: [
        {
          title: "Lead Management & Nurturing",
          description:
            "Use lead scoring to identify high-priority patients based on their health needs and engagement level. Automate follow-ups with appointment reminders and health tips.",
        },
        {
          title: "Campaign Automation",
          description:
            "Create automated email and SMS campaigns for appointment reminders, health newsletters, and educational content tailored to specific patient groups.",
        },
        {
          title: "CRM Integration",
          description:
            "Manage patient records, track interactions, and segment patients based on medical history and appointment history.",
        },
        {
          title: "Email Marketing Automation",
          description:
            "Send automated appointment confirmations, follow-up emails with care instructions, and seasonal health tips.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Track appointment no-shows, analyze patient engagement with health content, and measure the effectiveness of communication strategies.",
        },
      ],
      benefits: [
        {
          item: "Reduced appointment no-shows and improved patient adherence to care plans.",
        },
        {
          item: "Enhanced patient engagement through personalized and timely communication.",
        },
        {
          item: "Streamlined appointment management and better patient data management.",
        },
      ],
    },
    {
      title: "Financial Services Industry",
      icon: <FaChartLine />,
      sub_heading:
        "Improved client acquisition and conversion rates, enhanced client retention through personalized communication, and better management of client relationships and financial data.",
      topHeading: "Client Acquisition and Retention",
      scenario:
        "A financial services firm wants to improve client acquisition and retention through automated marketing and sales processes.",
      implementations: [
        {
          title: "Lead Management & Nurturing",
          description:
            "Implement lead scoring to prioritize high-value prospects and automate follow-ups with tailored financial product offers and educational content.",
        },
        {
          title: "Campaign Automation",
          description:
            "Develop drip campaigns to nurture leads with information about financial planning, investment opportunities, and account services.",
        },
        {
          title: "CRM Integration",
          description:
            "Track client interactions, manage account information, and segment clients based on their financial needs and service usage.",
        },
        {
          title: "Email Marketing Automation",
          description:
            "Automate onboarding emails for new clients, periodic investment updates, and personalized financial advice based on client profiles.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Monitor campaign performance, track client acquisition costs, and analyze retention rates to optimize marketing strategies.",
        },
      ],
      benefits: [
        {
          item: "Improved client acquisition and conversion rates through targeted marketing efforts.",
        },
        {
          item: "Enhanced client retention with personalized and relevant communication.",
        },
        {
          item: "Better management of client relationships and financial data.",
        },
      ],
    },
    {
      title: "Real Estate Industry",
      icon: <FaHome />,
      sub_heading:
        "Enhanced efficiency in managing property leads, improved marketing with personalized updates, and better insights into lead behavior and campaign performance, driving overall success.",
      topHeading: "Property Lead Management and Marketing",
      scenario:
        "A real estate agency wants to automate lead management and enhance property marketing efforts.",
      implementations: [
        {
          title: "Lead Management & Nurturing",
          description:
            "Use lead scoring to prioritize potential buyers based on their search criteria and engagement level. Automate follow-ups with property listings, virtual tour invitations, and mortgage information.",
        },
        {
          title: "Campaign Automation",
          description:
            "Set up automated email campaigns featuring new property listings, market updates, and personalized property recommendations.",
        },
        {
          title: "CRM Integration",
          description:
            "Manage lead and client information, track interactions, and segment leads based on property interests and purchase intent.",
        },
        {
          title: "Email Marketing Automation",
          description:
            "Automate notifications for new property listings, open houses, and price changes.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Track lead conversion rates, analyze the performance of property marketing campaigns, and measure the effectiveness of communication strategies.",
        },
      ],
      benefits: [
        {
          item: "Increased efficiency in managing property leads and follow-ups.",
        },
        {
          item: "Enhanced marketing efforts with personalized and automated property updates.",
        },
        {
          item: "Improved insights into lead behavior and campaign performance.",
        },
      ],
    },
    {
      title: "Travel and Hospitality Industry",
      icon: <FaHotel />,
      sub_heading:
        "Enhanced guest engagement, streamlined booking management, improved operational efficiency, and deeper insights into guest preferences and booking trends.",
      topHeading: "Guest Engagement and Booking Management",
      scenario:
        "A hotel chain wants to enhance guest engagement and streamline booking management through automation.",
      implementations: [
        {
          title: "Lead Management & Nurturing",
          description:
            "Implement lead scoring to identify high-potential guests based on their booking history and preferences. Use automated follow-ups to offer special promotions and booking reminders.",
        },
        {
          title: "Campaign Automation",
          description:
            "Create automated email campaigns for booking confirmations, pre-arrival information, and post-stay surveys. Send targeted offers based on guest preferences and booking history.",
        },
        {
          title: "CRM Integration",
          description:
            "Track guest interactions, manage booking details, and segment guests based on their stay history and preferences.",
        },
        {
          title: "Email Marketing Automation",
          description:
            "Automate communications for special promotions, seasonal offers, and personalized travel recommendations.",
        },
        {
          title: "Analytics & Reporting",
          description:
            "Monitor booking trends, analyze guest feedback, and track the effectiveness of marketing campaigns.",
        },
      ],
      benefits: [
        {
          item: "Enhanced guest engagement and satisfaction through targeted communication.",
        },
        {
          item: "Streamlined booking management and improved operational efficiency.",
        },
        {
          item: "Deeper insights into guest preferences and booking trends.",
        },
      ],
    },
  ],
};


