import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import TopNavbar from "components/TopNavbar";
import Images from "Images";
import { useMutation } from "react-query";
import axios from "axios";

import Loading from "components/Loading";
import Table from "components/EmailCampaign/Table";
import { PiPlusCircleBold } from "react-icons/pi";
import {
  Page,
  Container,
  Content,
  ContentHeader,
  HeaderTitle,
  HeaderSubTitle,
} from "components/Styles/PageStyles";
import NotFoundModel from "components/NotFoundModel";
import DeleteModel from "components/EmailCampaign/DeleteModel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ViewModel from "components/EmailCampaign/ViewModel";
import Config from "Config";

const EmailCampaign = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [searchType, setSearchType] = useState("STATUS");
  const [searchValue, setSearchValue] = useState("");
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  const [gotInitialResp, setGotInitialResp] = useState(false);
  const [openActionModel, setOpenActionModel] = useState(false);
  const [filterType, setFilterType] = useState("4");
  const [viewModel, setViewModel] = useState(false);
  const navigate = useNavigate();

  // ----- Getting Initial Data ------
  const fetchFunction = async (values) =>
    await axios.post(`${Config.apiUrl}/campaign/sms/list`, values, {
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

  const getListSuccess = (data) => {
    setGotInitialResp(true);
    //* Remove any item stored in the local storage related to the saved campaign
    localStorage.removeItem("initialValues");
    localStorage.removeItem("selectedTemplateName");
    localStorage.removeItem("selectedRecipientName");
    localStorage.removeItem("selectedTemplateId");
    localStorage.removeItem("templateId");
  };
  const getListError = (data) => {
    setGotInitialResp(true);
  };

  const {
    isLoading,
    error,
    data,
    mutate: getListMutate,
  } = useMutation(fetchFunction, {
    onSuccess: getListSuccess,
    onError: getListError,
  });
  useEffect(() => {
    const handler = setTimeout(() => {
      getListMutate({
        searchValue: searchValue,
        filterType: filterType,
        type: Config.CampaignType.Email,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, filterType]);

  useEffect(() => {
    setSearchValue("");
  }, [filterType]);

  const ChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  //------- Delete Group -------
  const deleteFunction = async (values) =>
    await axios.post(
      `${Config.apiUrl}/campaign/sms/delete`,
      values,

      {
        headers: {
          "Content-Type": "application/json",
          Token: `${user.token}`,
        },
      }
    );

  const deleteSuccess = (data) => {
    // refetch();
    getListMutate({
      searchValue: searchValue,
      type: Config.CampaignType.Email,
    });
    setSelectedData("");
    setDeleteModel(false);
    toast.success(data?.data?.msg || "Success");
  };

  const deleteError = (data) => {
    setDeleteModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(
    deleteFunction,
    {
      onSuccess: deleteSuccess,
      onError: deleteError,
    }
  );

  //------- Active/Inactive Template -------
  const actionFunction = async (values) =>
    await axios.post(
      `${Config.apiUrl}/blockContact`,
      values,

      {
        headers: {
          "Content-Type": "application/json",
          Token: `${user.token}`,
        },
      }
    );

  const actionSuccess = (data) => {
    getListMutate({
      searchValue: searchValue,
      type: Config.CampaignType.Email,
    });
    setSelectedData("");
    setOpenActionModel(false);
    toast.success(data?.data?.msg || "Success");
  };

  const actionError = (data) => {
    getListMutate({
      searchValue: searchValue,
    });
    setOpenActionModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: actionDeviceLoading, mutate: actionDeviceMutate } =
    useMutation(actionFunction, {
      onSuccess: actionSuccess,
      onError: actionError,
    });

  return (
    <>
      <Container>
        <Page>
          <TopNavbar />
          <Content>
            <ContentHeader>
              <div className="flex items-center justify-between">
                <HeaderTitle>Email Campaigns</HeaderTitle>
                {user.type == Config.UserType.ClientUser && (
                  <Link to="/email/campaign/create">
                    <Button className="rounded-xl" type="button">
                      <PiPlusCircleBold size={18} />{" "}
                      <p className="font-semibold">Create</p>
                    </Button>
                  </Link>
                )}
              </div>
              <HeaderSubTitle>
                Reach your audience directly and boost engagement with
                personalized, effective Email campaigns.
              </HeaderSubTitle>
            </ContentHeader>

            <ButtonGroup>
              <TemplateButton
                isActive={filterType === "4"}
                onClick={() => setFilterType("4")}
              >
                All
              </TemplateButton>
              <TemplateButton
                ml
                isActive={filterType === "3"}
                onClick={() => setFilterType("3")}
              >
                Sent
              </TemplateButton>
              <TemplateButton
                ml
                isActive={filterType === "1"}
                onClick={() => setFilterType("1")}
              >
                Scheduled
              </TemplateButton>
              <TemplateButton
                ml
                isActive={filterType === "2"}
                onClick={() => setFilterType("2")}
              >
                Running
              </TemplateButton>
            </ButtonGroup>
            <Seperator />
            <div className="flex w-full justify-between items-center">
              <Uploader
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                searchType={searchType}
                setSearchType={setSearchType}
                ChangeHandler={ChangeHandler}
                setOpenUploadModel={setOpenUploadModel}
              />
            </div>

            {deleteModel && (
              <DeleteModel
                setDeleteModel={setDeleteModel}
                deleteLoading={deleteLoading}
                selectedData={selectedData}
                deleteMutate={deleteMutate}
              />
            )}

            {viewModel && (
              <ViewModel
                setViewModel={setViewModel}
                selectedData={selectedData}
              />
            )}

            {!isLoading && gotInitialResp && (
              <TableWrapper>
                <Table
                  ApiData={error ? [] : data?.data?.data}
                  setSelectedData={setSelectedData}
                  setDeleteModel={setDeleteModel}
                  setOpenActionModel={setOpenActionModel}
                  setViewModel={setViewModel}
                />
                {(error || data?.data?.data?.length === 0) &&
                  !isLoading &&
                  gotInitialResp && <NotFoundModel />}
              </TableWrapper>
            )}

            {(isLoading || !gotInitialResp) && <Loading />}
          </Content>
        </Page>
      </Container>
    </>
  );
};

const Uploader = ({ searchValue, ChangeHandler }) => {
  return (
    <>
      <SearchWrapper>
        <SearchInput>
          <img src={Images.SearchIcon} alt="" />
          <input
            type="text"
            placeholder={`Type in to search...`}
            onChange={ChangeHandler}
            value={searchValue}
            className=""
          />
        </SearchInput>
      </SearchWrapper>
    </>
  );
};

const TableWrapper = tw.div` border rounded-md `;
const Button = tw.button`text-white bg-orange-500 hover:bg-orange-600 px-8 flex items-center space-x-1 justify-center h-10 text-base whitespace-nowrap rounded`;
const SearchWrapper = tw.div`flex items-center w-full max-w-sm ml-auto`;
const SearchInput = tw.div` field-wrapper relative px-2 gap-2 rounded-xl bg-white h-10 border border-zinc-400 flex items-center overflow-hidden ml-auto`;
const ButtonGroup = tw.div`flex items-center !gap-0`;
const TemplateButton = tw.button`
  px-2 font-semibold relative ${(props) =>
    props.isActive ? "text-blue-500" : "text-black"}
  ${(props) => (props.ml ? "ml-2" : "")}
  after:absolute after:top-11 after:left-0 after:w-full after:h-0.5
  ${(props) => (props.isActive ? "after:bg-blue-500" : "")}
`;
const Seperator = tw.div`w-full h-[0.090rem] bg-gray-200 `;
export default EmailCampaign;
