
import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    InputGroup,
    FieldWrappers,
    Label,
    SubmitBtn,
    CancelBtn,
} from "../Styles/InputStyles";
import { MdClose } from "react-icons/md";
import Loading from "../Loading";


const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string()
        .matches(/^[0-9]{7,15}$/, "Phone Number must be between 7 and 15 digits")
        .required("Phone Number is required"),
});

const CreateContactModel = ({ setContactModel, createContactMutate, createContactLoading, groupId }) => {
    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        whatsapp: "",
        groupId: groupId,
    };

    const handleSubmit = (values) => {
        createContactMutate({
            firstName: values.firstname,
            lastName: values.lastname,
            email: values?.email,
            sms: values?.phoneNumber,
            whatsapp: values?.whatsapp,
            groupId: groupId,
        });
    };

    return (
        <div className="bg-white w-full h-full flex flex-col">
            <div className="flex items-center justify-between bg-[#06173A] py-10 px-4">
                <Title className="text-white">Create Contact</Title>
                <button
                    onClick={() => {
                        setContactModel(false);
                    }}
                >
                    <MdClose color="white" size={24} />
                </button>
            </div>
            {createContactLoading && <Loading />}
            <div className="px-8 flex-grow flex flex-col justify-between pb-4">
                {!createContactLoading && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col h-full justify-between">
                                <Wrapper>
                                    <InputGroup>
                                        <Label htmlFor="firstname">First Name *</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="text"
                                                name="firstname"
                                                id="firstname"
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                            />

                                        </FieldWrappers>
                                        {errors.firstname && touched.firstname ? (
                                            <div className="text-red-500">{errors.firstname}</div>
                                        ) : null}
                                    </InputGroup>
                                    <InputGroup>
                                        <Label htmlFor="lastname">Last Name *</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="text"
                                                name="lastname"
                                                id="lastname"
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                            />

                                        </FieldWrappers>
                                        {errors.lastname && touched.lastname ? (
                                            <div className="text-red-500">{errors.lastname}</div>
                                        ) : null}
                                    </InputGroup>

                                    <InputGroup>
                                        <Label htmlFor="phoneNumber">Phone Number *</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="tel"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                            />

                                        </FieldWrappers>
                                        {errors.phoneNumber && touched.phoneNumber ? (
                                            <div className="text-red-500">{errors.phoneNumber}</div>
                                        ) : null}
                                    </InputGroup>
                                    <InputGroup>
                                        <Label htmlFor="email">Email</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="email"
                                                name="email"
                                                id="email"
                                                autoComplete="off"
                                                className="truncate"
                                            />
                                        </FieldWrappers>
                                    </InputGroup>
                                    <InputGroup>
                                        <Label htmlFor="whatsapp">WhatsApp</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="tel"
                                                name="whatsapp"
                                                id="whatsapp"
                                                autoComplete="off"
                                                className="truncate"
                                            />
                                        </FieldWrappers>
                                    </InputGroup>
                                </Wrapper>
                                <div className="flex justify-between p-4">
                                    <CancelBtn type="button" onClick={() => setContactModel(false)}>
                                        Cancel
                                    </CancelBtn>
                                    <BtnWrapper>
                                        <SubmitBtn type="submit">Create</SubmitBtn>
                                    </BtnWrapper>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

const Wrapper = tw.div`grid md:gap-4 md:my-4 `;
const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;

export default CreateContactModel;
