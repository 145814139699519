import Images from "Images";

export const benefits = [
  {
    title: "Improved Market Reach",
    image: Images.outreach,
    description:
      "Multi-channel marketing campaigns enable businesses to reach farmers, consumers, and stakeholders across different communication platforms, maximizing visibility and impact.",
  },
  {
    title: "Enhanced Customer Engagement",
    image: Images.publicRelations,
    description:
      "Personalized messaging and feedback loops help businesses build stronger relationships with consumers and partners, fostering loyalty and trust.",
  },
  {
    title: "Automation for Efficiency",
    image: Images.onboarding,
    description:
      "Automated SMS, email, and social media campaigns reduce manual workload, allowing businesses to focus on growth and innovation while ensuring consistent communication.",
  },
  {
    title: "Increased Productivity",
    image: Images.productive,
    description:
      "Features like USSD menus and appointment management streamline farm operations, ensuring higher productivity and better resource allocation.",
  },
  {
    title: "Data-Driven Decision Making",
    image: Images.monitor,
    description:
      "Detailed reporting and analytics empower businesses to make informed decisions based on customer feedback and campaign performance, leading to more strategic actions.",
  },
  {
    title: "Sustainable Practices Promotion",
    image: Images.sustainability,
    description:
      "Social media and other campaign tools help promote eco-friendly and sustainable agricultural and forestry practices, encouraging responsible resource management.",
  },
];

export const features = [
  {
    title: "Farmer Education and Training Campaign",
    useCase:
      "Provide farmers with critical training on modern farming techniques and sustainable practices.",
    mechanics:
      "Run SMS Bulk Messaging and Email Campaigns to deliver training. Use Voice Broadcasts for those in rural areas to deliver content in local languages.",
    image: Images.farmer_ed,
    benefit:
      "Improved adoption of best practices, higher crop yields, and better farmer engagement through accessible and personalized messaging.",
  },
  {
    title: "Crop Price Alerts and Market Information",
    useCase:
      "Send real-time market price alerts to farmers to enable informed selling decisions.",
    mechanics:
      "Use Bulk SMS for real-time alerts and USSD Menu for instant access to pricing and demand trends.",
    image: Images.food_price,
    benefit:
      "Empowered farmers with up-to-date information, better pricing for crops, and minimized losses due to poor market timing.",
  },
  {
    title: "Consumer Feedback on Agricultural Products",
    useCase:
      "Collect consumer feedback on agricultural product quality to improve satisfaction and loyalty.",
    mechanics:
      "Utilize Polling, Survey, and Feedback Management via SMS and Social Media. Automated surveys can be triggered after purchases.",
    image: Images.consumer_feedback,
    benefit:
      "Improved product quality based on consumer feedback, enhanced customer satisfaction, and stronger brand loyalty.",
  },
  {
    title: "Agricultural Fair and Expo Promotion",
    useCase: "Promote agricultural expos or fairs to increase participation and networking.",
    mechanics:
      "Manage Social Media Campaigns across platforms like Facebook, Instagram, and WhatsApp. Use AI Integration for engaging content and Campaign Scheduling for consistent messaging.",
    image: Images.religion_img_four,
    benefit:
      "Higher event participation, increased exposure to innovations in agriculture, and stronger connections between producers and consumers.",
  },
  {
    title: "Farmer and Supplier Appointment Management",
    useCase:
      "Schedule and manage appointments between farmers and suppliers for equipment purchases or consultations.",
    mechanics:
      "Use the Meetings & Appointment Management Module to schedule appointments. Automated reminders sent via SMS and Email.",
    image: Images.farmer_supplier,
    benefit:
      "Improved coordination, increased efficiency in managing supplier relationships, and reduced missed appointments.",
  },
  {
    title: "Automated Harvest Season Marketing",
    useCase:
      "Promote freshly harvested produce using multi-channel marketing during peak seasons.",
    mechanics:
      "Use Sales & Marketing Automation to send Bulk SMS and Email Campaigns. AI tailors content to engage buyers across channels like WhatsApp and Telegram.",
    image: Images.automated_farming,
    benefit:
      "Increased sales during peak harvest seasons, improved market reach, and higher demand for seasonal crops.",
  },
  {
    title: "Forestry Conservation Campaigns",
    useCase:
      "Raise awareness and gather support for forestry conservation efforts.",
    mechanics:
      "Combine Bulk Messaging (SMS and Email) with Social Media platforms like Instagram and Facebook. Utilize Lead Generation Module for capturing interested donors or partners.",
    image: Images.food_conserve,
    benefit:
      "Greater public awareness, more partnerships for conservation projects, and stronger support for sustainable forestry practices.",
  },
  {
    title: "Loyalty Programs for Agro-Supply Stores",
    useCase:
      "Reward repeat customers with discounts to increase customer retention and revenue.",
    mechanics:
      "Implement Loyalty Management Campaigns using Bulk SMS and Email. Use Omni-Channel Engagement to reach customers through their preferred communication channels.",
    image: Images.agro_fertilizer,
    benefit:
      "Increased customer retention, higher sales from loyal customers, and improved store revenue.",
  },
  {
    title: "Animal Health and Veterinary Service Scheduling",
    useCase:
      "Facilitate veterinary appointments for livestock health checks and improve farm productivity.",
    mechanics:
      "Use USSD Menus and Bulk SMS for scheduling. The Meetings & Appointments Module ensures reminders are sent automatically to both farmers and veterinarians.",
    image: Images.animal_health,
    benefit:
      "Improved animal health, reduced missed appointments, and better farm productivity.",
  },
  {
    title: "Organic Produce Subscription Management",
    useCase:
      "Promote subscription services for organic produce delivery to increase direct-to-consumer sales.",
    mechanics:
      "Use Social Media Marketing and Email Campaigns to promote subscriptions. Contact List Management segments subscribers based on product preferences.",
    image: Images.religion_img_ten,
    benefit:
      "Increased direct-to-consumer sales, better customer retention, and consistent revenue streams for organic farms.",
  },
];




