import Images from "Images";

export const benefits = [
  {
    title: "Enhanced Multi-Channel Reach",
    description:
      "Advertising agencies can manage campaigns across multiple platforms, including email, SMS, social media, and USSD, ensuring widespread visibility.",
    image: Images.channelReach,
  },
  {
    title: "AI-Driven Personalization",
    description:
      "Tailor campaigns to specific customer segments with AI-generated content, improving engagement and relevance.",
    image: Images.aiDriven,
  },
  {
    title: "Real-Time Analytics",
    description:
      "Track campaign performance and make data-driven decisions with detailed analytics and reporting tools.",
    image: Images.dataDriven,
  },
  {
    title: "Customer Engagement & Feedback",
    description:
      "Capture customer preferences through surveys, polls, and social media interactions, ensuring campaigns are aligned with audience needs.",
    image: Images.feedback,
  },
  {
    title: "Automation & Scheduling",
    description:
      "Easily automate and schedule campaigns for different seasons or target markets, saving time and ensuring consistency.",
    image: Images.automation,
  },
];


export const features = [
  {
    title: "Product Launch Campaign",
    useCase:
      "Manage a multi-channel product launch campaign for increased awareness and engagement.",
    mechanics:
      "Run targeted campaigns on social media platforms (Facebook, Instagram, YouTube, TikTok) and send teaser messages via SMS and Email. Announce the launch to premium clients via Voice Broadcast.",
    image: Images.religion_img_one,
    benefit:
      "Increased awareness, high engagement through personalized content, and multi-platform reach.",
  },
  {
    title: "Event Promotion Campaign",
    useCase:
      "Promote product unveiling events and manage attendee interactions.",
    mechanics:
      "Use Bulk SMS and Email to invite attendees. Manage one-on-one consultations with the Meetings & Appointments Module and drive RSVPs through Social Media Campaigns on LinkedIn and Instagram.",
    image: Images.religion_img_two,
    benefit:
      "Streamlined booking, improved event turnout, and better audience engagement through multiple touchpoints.",
  },
  {
    title: "Brand Awareness for New Market Entry",
    useCase:
      "Introduce a global brand to a new market with localized campaigns.",
    mechanics:
      "Utilize Geo-Location Campaign Management to tailor ads and use Social Media Marketing on Facebook and Twitter. Engage users via USSD Menu for surveys and lead generation.",
    image: Images.religion_img_three,
    benefit:
      "Effective localization, enhanced regional engagement, and precise audience targeting.",
  },
  {
    title: "Influencer-Led Social Media Campaign",
    useCase:
      "Leverage influencer marketing to boost a new product line's visibility.",
    mechanics:
      "Collaborate with influencers to promote products via posts, reels, and stories on Instagram and TikTok. Use AI Content Creation to optimize captions and content.",
    image: Images.religion_img_four,
    benefit:
      "Broader organic reach, efficient management of influencer content, and higher engagement through influencer marketing.",
  },
  {
    title: "Loyalty Program Promotion",
    useCase:
      "Reward repeat customers to increase retention and loyalty.",
    mechanics:
      "Design and execute a Loyalty Campaign using Bulk SMS and Email Marketing. Collect feedback through Surveys and Feedback Modules.",
    image: Images.religion_img_five,
    benefit:
      "Increased customer retention, real-time feedback, and higher brand loyalty through personalized rewards.",
  },
  {
    title: "Lead Generation for a New Service",
    useCase:
      "Capture business leads for a new B2B service offering.",
    mechanics:
      "Use USSD Menu Builder for lead capture and promote via Social Media Ads on LinkedIn and YouTube. Follow up with qualified leads through Email Marketing.",
    image: Images.religion_img_six,
    benefit:
      "Efficient lead capture, improved sales conversion, and seamless follow-up through automated workflows.",
  },
  {
    title: "Multi-Platform Holiday Campaign",
    useCase:
      "Run a comprehensive holiday sales campaign.",
    mechanics:
      "Utilize the Multi-Channel Campaign Module to execute Bulk SMS, Email, and Social Media campaigns. Reach high-value customers with Voice Broadcasts.",
    image: Images.religion_img_seven,
    benefit:
      "High campaign reach, efficient cross-platform coordination, and increased holiday sales.",
  },
  {
    title: "New Store Opening",
    useCase:
      "Promote a new store opening and engage local customers.",
    mechanics:
      "Send targeted ads and SMS Notifications using Geo-Location Campaign Management. Use Polling Module to gather community feedback on giveaways.",
    image: Images.religion_img_eight,
    benefit:
      "Targeted local outreach, community engagement through polling, and increased store traffic.",
  },
  {
    title: "Polling-Based Customer Insights",
    useCase:
      "Gather customer feedback on new product flavors.",
    mechanics:
      "Distribute surveys via USSD Menu and Bulk SMS, and analyze responses with AI Analytics.",
    image: Images.religion_img_nine,
    benefit:
      "Real-time customer insights, data-driven decision-making, and tailored product offerings.",
  },
  {
    title: "Cross-Channel Remarketing",
    useCase:
      "Re-engage customers through tailored remarketing campaigns.",
    mechanics:
      "Create remarketing emails and SMS messages using AI Content Creation. Run Social Media Remarketing Campaigns on Facebook and Instagram.",
    image: Images.religion_img_ten,
    benefit:
      "Increased conversions through personalized messages, better re-engagement, and improved sales.",
  },
  {
    title: "Webinar & Educational Campaign",
    useCase:
      "Drive webinar registrations and manage post-event interactions.",
    mechanics:
      "Promote webinars using Bulk SMS, Email, and Social Media Ads. Schedule follow-up meetings using the Meetings & Appointments Module.",
    image: Images.religion_img_one,
    benefit:
      "High registration rates, post-event engagement, and stronger client relationships.",
  },
  {
    title: "Public Opinion Polling Campaign",
    useCase:
      "Conduct political polling and gather voter opinions.",
    mechanics:
      "Run SMS Polling and Social Media Polls to collect public opinions using SEVO’s Polling and Feedback Management Module.",
    image: Images.religion_img_two,
    benefit:
      "Broader audience reach, real-time opinion analysis, and data-driven campaign adjustments.",
  },
  {
    title: "Product Trial Campaign",
    useCase:
      "Promote product trials and gather user feedback.",
    mechanics:
      "Send invites for free product trials via Bulk Messaging and Email. Collect feedback through a Survey module and share user experiences on Social Media.",
    image: Images.religion_img_three,
    benefit:
      "Enhanced customer engagement, valuable trial feedback, and user-generated content for promotion.",
  },
  {
    title: "Seasonal Promotion and Campaign Scheduling",
    useCase:
      "Plan and execute seasonal promotions effectively.",
    mechanics:
      "Use Campaign Scheduling to manage promotions for holidays. Run Social Media, Bulk SMS, and Email campaigns to drive sales and brand awareness.",
    image: Images.religion_img_four,
    benefit:
      "Optimized timing, increased sales during peak seasons, and improved brand visibility.",
  },
  {
    title: "Healthcare Awareness Campaign",
    useCase:
      "Educate customers about new healthcare products.",
    mechanics:
      "Run Email, SMS, and Social Media campaigns to inform about new drugs. Use USSD Surveys to capture feedback from healthcare providers and patients.",
    image: Images.religion_img_five,
    benefit:
      "Increased product knowledge, enhanced patient engagement, and valuable health data insights.",
  },
];





