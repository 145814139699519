import Images from "Images";

export const benefits = [
  {
    title: "Boosts Enrollment",
    image: Images.enrollment,
    description:
      "Attracts and engages prospective students through effective multi-channel marketing.",
  },
  {
    title: "Streamlines Results Notifications",
    image: Images.notification,
    description:
      "Automates distribution of results and updates, improving communication efficiency.",
  },
  {
    title: "Enhances Parent-Teacher Communication",
    image: Images.parentTeacher,
    description:
      "Facilitates regular updates and interactions between parents and teachers.",
  },
  {
    title: "Strengthens Alumni Relations",
    image: Images.alumni,
    description:
      "Keeps alumni engaged with targeted campaigns, fostering community and support.",
  },
  {
    title: "Increases Event Participation",
    image: Images.eventParticipation,
    description:
      "Promotes school events effectively to ensure higher community involvement.",
  },
  {
    title: "Promotes Online Courses",
    image: Images.onlineCourse,
    description:
      "Drives enrollment in online programs through targeted digital marketing.",
  },
  {
    title: "Improves Scheduling Transparency",
    image: Images.scheduling,
    description:
      "Communicates class schedules and changes effectively to avoid confusion.",
  },
  {
    title: "Collects Valuable Feedback",
    image: Images.feedback,
    description:
      "Gathers and analyzes student feedback to enhance educational experiences.",
  },
  {
    title: "Enhances Emergency Communication",
    image: Images.emergency,
    description:
      "Provides reliable alerts and updates during emergencies for safety.",
  },

];

export const features = [
  {
    title: "Student Recruitment and Course Enrollment Campaigns",
    useCase:
      "Attract potential students and boost enrollment in both in-person and online programs through targeted multi-channel marketing.",
    mechanics:
      "SMS, email marketing, and social media campaigns to attract potential students and promote online courses.",
    image: Images.religion_img_one,
    benefit:
      "Increased student enrollment by reaching a wider audience through multi-channel outreach and targeted advertising.",
  },
  {
    title: "Automated Results Notifications and Emergency Alerts",
    useCase:
      "Automate communication for results notifications and emergency alerts to ensure timely delivery of critical information.",
    mechanics:
      "Automated bulk SMS/email for results notifications and instant SMS alerts or voice broadcasts for emergencies.",
    image: Images.religion_img_two,
    benefit:
      "Timely and efficient delivery of important information, reducing administrative burden and enhancing safety.",
  },
  {
    title: "Parent-Teacher and Lecturer-Student Communication",
    useCase:
      "Facilitate engagement between parents, teachers, and students to improve educational outcomes and relationships.",
    mechanics:
      "Personalized messages via bulk SMS, WhatsApp, and CRM integration for tracking interactions and scheduling appointments.",
    image: Images.religion_img_three,
    benefit:
      "Enhanced engagement and better relationships, improving academic performance and student outcomes.",
  },
  {
    title: "Alumni and Community Engagement Campaigns",
    useCase:
      "Engage alumni and promote school events to foster community relations and support.",
    mechanics:
      "Email marketing, social media posts, and voice broadcasts to promote events and keep alumni informed.",
    image: Images.religion_img_four,
    benefit:
      "Stronger alumni relationships, increased donations, and higher attendance at school events.",
  },
  {
    title: "Class Schedule and Course Material Announcements",
    useCase:
      "Effectively communicate class schedules, changes, and distribute course materials to students.",
    mechanics:
      "Bulk messaging via SMS or email to inform about schedules and email campaigns for course material distribution.",
    image: Images.religion_img_five,
    benefit:
      "Improved scheduling transparency and preparedness, reducing confusion and absenteeism.",
  },
  {
    title: "Student Feedback, Surveys, and Quick Assessments",
    useCase:
      "Gather valuable feedback and conduct quick assessments to improve educational experiences.",
    mechanics:
      "Polls, surveys, feedback forms, and live polling during lectures via SMS, email, or USSD menus.",
    image: Images.religion_img_six,
    benefit:
      "Actionable insights into student satisfaction and real-time feedback for lecturers.",
  },
  {
    title: "Scholarship Awareness and Loyalty Programs",
    useCase:
      "Increase awareness of financial aid opportunities and motivate students with reward-based loyalty programs.",
    mechanics:
      "Targeted SMS, social media, email campaigns for scholarships, and loyalty management systems for rewards.",
    image: Images.religion_img_seven,
    benefit:
      "Higher application rates for scholarships and improved student retention and motivation.",
  },
  {
    title: "Event Promotion and Open Day Campaigns",
    useCase:
      "Promote school events like open days, graduation ceremonies, and cultural activities to maximize participation.",
    mechanics:
      "Social media marketing, SMS, and voice broadcasts for effective promotion of school events.",
    image: Images.religion_img_eight,
    benefit:
      "Increased awareness and attendance at events, enhancing community relations and student recruitment.",
  },
  {
    title: "Appointment Scheduling and Relationship Management",
    useCase:
      "Simplify the scheduling of meetings and manage relationships between students, lecturers, and administrators.",
    mechanics:
      "Appointment management systems with automated reminders via SMS and email, integrated with CRM.",
    image: Images.religion_img_nine,
    benefit:
      "Increased productivity and accessibility through simplified scheduling and enhanced relationship management.",
  },
  {
    title: "School Branding and Reputation Campaigns",
    useCase:
      "Promote the school’s brand image and reputation across various digital platforms to attract students and partners.",
    mechanics:
      "Social media marketing campaigns on platforms like Instagram, LinkedIn, and YouTube.",
    image: Images.religion_img_ten,
    benefit:
      "Enhanced school reputation and visibility, attracting more students and fostering partnerships.",
  },
];





