import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { MdArrowBack, MdArrowForward, MdDelete } from "react-icons/md";
import Config from "Config";
import { useSelector } from "react-redux";
import { GoCheckCircle } from "react-icons/go";
import { IoMdMore } from "react-icons/io";
import { CiEdit } from "react-icons/ci";

const Table = ({
  ApiData,
  setSelectedData,
  setDeleteModel,
  setViewModel,
  setOpenActionModel,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const [data, setData] = useState([]);
  const [openActionMenu, setOpenActionMenu] = useState(null);

  const deleteThisDevice = (id) => {
    setDeleteModel(true);
    setSelectedData(id);
  };
  const View = (item) => {
    setViewModel(true);
    setSelectedData(item);
  };

  const getStatus = (status) => {
    if (status === 1) {
      return <span>Approved</span>;
    } else if (status === 2) {
      return <span>Rejected</span>;
    } else {
      return <span>Pending</span>;
    }
  };





  const getId = (item) => {
    return (
      <span className="bg-green-200 text-black-700 px-4 py-1 rounded-sm inline-flex items-center gap-2">
        #{item.id}
      </span>
    );
  };
  const ActionBtns = (item) => {
    const openMenu = (id) => {
      setOpenActionMenu((prevId) => (prevId === id ? null : id));
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            <MenuItem onClick={() => console.log("edited")}>
              <CiEdit className="text-gray-700 mr-2" /> Edit Survey
            </MenuItem>
            <Seperator />
            <MenuItem onClick={() =>console.log("deleted")}>
              <MdDelete className="text-red-700 mr-2" /> Delete Survey
            </MenuItem>
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        title:item.title,
        id: getId(item.id),
        created: moment(item.created_at).format("DD MMM YYYY, HH:mm"),
        status: getStatus(item.status),
        userName: item.userName || "N/A",
        actions:ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: user.type === Config.UserType.SuperAdminUser ? "User" : "",
        accessor:
          user.type === Config.UserType.SuperAdminUser
            ? "userName"
            : "undefined",
      },
      {
        Header:"ID",
        accessor:"id"
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created",
        accessor: "created_at",
      },
      {
        Header:"Action",
        accessor:'actions'
      }
    ],
    [user.type]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, [setPageSize]);

  return (
    <>
      <CustomTable {...getTableProps()} className="w-full">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <MdArrowBack className="inline-block ml-1" />
                      ) : (
                        <MdArrowForward className="inline-block ml-1" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData.length != false && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <MdArrowBack className="w-4 h-4" />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <MdArrowForward className="w-4 h-4" />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const CustomTable = tw.table`w-full`;
const Thead = tw.thead`rounded-md`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;


const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-100`;

const Th = tw.th`text-md p-4 font-semibold text-gray-700 text-left`;
const Td = tw.td`p-3 text-md`;

export const Seperator = tw.div`w-full h-0.5 bg-gray-200 my-2`;

export default Table;
