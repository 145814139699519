import Images from "Images";

export const benefits = [
  {
    title: "Improved Communication and Engagement",
    image: Images.outreach,
    description:
      "Multi-channel communication capabilities ensure that messages reach all relevant stakeholders, from employees to investors and communities, enhancing overall engagement and relationships.",
  },
  {
    title: "Automation for Operational Efficiency",
    image: Images.onboarding,
    description:
      "Automation features, such as automated messaging and scheduling, save time and resources, allowing companies to focus on core operations like exploration, extraction, and energy production.",
  },
  {
    title: "Enhanced Safety and Crisis Management",
    image: Images.productive,
    description:
      "Advanced communication tools improve the management of safety protocols, shift schedules, and emergency alerts, supporting operational continuity and safety during crises.",
  },
  {
    title: "Stronger Public Relations and Reputation Management",
    image: Images.publicRelations,
    description:
      "Social media campaigns and feedback mechanisms help improve public perception, especially in environmentally sensitive industries, fostering better community and investor relations.",
  },
  {
    title: "Data-Driven Insights for Optimization",
    image: Images.monitor,
    description:
      "Detailed analytics on campaign performance, feedback collection, and audience engagement provide actionable insights, helping companies optimize their operations and marketing efforts.",
  },
  {
    title: "Promoting Sustainable Growth",
    image: Images.sustainability,
    description:
      "Marketing and awareness campaigns for renewable energy projects foster the growth of sustainable energy practices, encouraging responsible development within communities and industries.",
  },
];


export const features = [
  {
    title: "Community Safety Awareness Campaigns",
    useCase:
      "Notify communities near mining or energy sites about safety protocols and gather feedback on safety concerns.",
    mechanics:
      "Use SMS Bulk Messaging and Voice Broadcast to notify communities about upcoming projects, safety protocols, and evacuation procedures. Use the Polls & Surveys Module to gather feedback.",
    image: Images.religion_img_one,
    benefit:
      "Enhanced community relations, improved safety compliance, and reduced risk of disruptions during operations.",
  },
  {
    title: "Environmental and Conservation Campaigns",
    useCase:
      "Promote environmental conservation and corporate social responsibility (CSR) initiatives.",
    mechanics:
      "Leverage Social Media Campaigns on platforms like Facebook, Instagram, and YouTube to promote CSR initiatives. Use Campaign Scheduling to align posts with key dates like Earth Day.",
    image: Images.religion_img_two,
    benefit:
      "Improved brand image, stronger environmental advocacy, and greater public support for sustainability efforts.",
  },
  {
    title: "Employee Shift Management and Safety Training",
    useCase:
      "Manage employee shifts and appointments while ensuring compliance with safety training and certifications.",
    mechanics:
      "Use the Meetings & Appointment Management Module for scheduling shifts and performance reviews. Automate reminders via SMS and Email for safety training and certification renewals.",
    image: Images.religion_img_three,
    benefit:
      "Increased operational efficiency, reduced missed shifts, and enhanced workforce readiness.",
  },
  {
    title: "Investor Relations and Updates",
    useCase:
      "Keep investors informed about company developments and build stronger relationships.",
    mechanics:
      "Send Bulk Email Campaigns and Voice Broadcasts for updates on new developments, quarterly reports, or environmental impact assessments. Use AI-driven content for clarity and professionalism.",
    image: Images.religion_img_four,
    benefit:
      "Stronger investor confidence, transparent communication, and improved stakeholder relationships.",
  },
  {
    title: "Lead Generation for Renewable Energy Solutions",
    useCase:
      "Attract potential clients for renewable energy solutions like solar, wind, or geothermal.",
    mechanics:
      "Leverage Lead Generation Campaigns using Social Media Marketing across platforms like LinkedIn and Facebook.",
    image: Images.religion_img_five,
    benefit:
      "Increased business opportunities in the renewable energy space, better client acquisition, and higher sales of green energy products.",
  },
  {
    title: "Employee Feedback and Engagement Surveys",
    useCase:
      "Collect feedback from employees to improve workplace conditions and morale.",
    mechanics:
      "Utilize the Survey Module to gather employee feedback on workplace conditions and job satisfaction via SMS or WhatsApp. Analyze results in the Reporting & Analytics Module.",
    image: Images.religion_img_six,
    benefit:
      "Improved employee morale, reduced turnover, and enhanced decision-making based on real-time feedback.",
  },
  {
    title: "Loyalty Programs for Energy Equipment Suppliers",
    useCase:
      "Reward suppliers and contractors who frequently purchase energy equipment to boost loyalty.",
    mechanics:
      "Implement Loyalty Management Campaigns using Omni-Channel Engagement through SMS, Email, and WhatsApp.",
    image: Images.religion_img_seven,
    benefit:
      "Higher supplier retention, increased repeat purchases, and improved supply chain efficiency.",
  },
  {
    title: "Crisis Communication During Emergencies",
    useCase:
      "Deliver real-time updates and instructions to stakeholders during emergencies such as oil spills or gas leaks.",
    mechanics:
      "Use Bulk SMS, Voice Broadcast, and WhatsApp Messaging for real-time communication. Track message reach and response rates with Detailed Reporting & Analytics.",
    image: Images.religion_img_eight,
    benefit:
      "Faster response times, reduced risk of fatalities or environmental damage, and increased public safety.",
  },
  {
    title: "Corporate Event and Conference Management",
    useCase:
      "Invite stakeholders to corporate events and manage follow-up meetings.",
    mechanics:
      "Use Bulk SMS and Email Campaigns to invite stakeholders to conferences or events. Schedule follow-up meetings with the Meetings & Appointments Module.",
    image: Images.religion_img_nine,
    benefit:
      "Increased event attendance, stronger business connections, and more opportunities for partnerships.",
  },
  {
    title: "Consumer Awareness and Engagement for Renewable Energy",
    useCase:
      "Educate consumers on the benefits of renewable energy solutions to drive sales and adoption.",
    mechanics:
      "Run Social Media Campaigns on platforms like Facebook, Instagram, and YouTube. Use Sales & Marketing Automation for targeted follow-up through Email, SMS, and Social Media.",
    image: Images.religion_img_ten,
    benefit:
      "Higher consumer engagement, increased sales of renewable energy solutions, and greater public awareness of environmental benefits.",
  },
];





