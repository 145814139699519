
import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    InputGroup,
    FieldWrappers,
    Label,
    SubmitBtn,
    CancelBtn
} from "../Styles/InputStyles";
import Loading from "../Loading";
import { MdClose } from "react-icons/md";

const CreateSenderId = ({
    setOpenUploadModel,
    SenderIdMutate,
    SenderIdLoading,
}) => {
    const [bodyCharCount, setBodyCharCount] = useState(0);
    const initialValues = {
        senderId: "",
        description: "",
    };

    const validationSchema = Yup.object({
        // senderId: Yup.string()
        //     .matches(/^[a-zA-Z0-9]*$/, "Sender ID must be alphanumeric")
        //     .test('is-alphanumeric', 'Sender ID must contain both letters and numbers', value =>
        //         /[a-zA-Z]/.test(value) && /[0-9]/.test(value)
        //     )
        //     .required("Sender ID is required"),
        senderId: Yup.string()
            .matches(/^[^\s!@#$%^&*(),.?":{}|<>]*$/, "Sender ID must not contain spaces or special characters")
            .required("Sender ID is required"),
        description: Yup.string().required("Description is required"),
    });

    const handleSubmit = (values, { resetForm }) => {
        SenderIdMutate({
            senderId: values.senderId.toUpperCase(),
            description: values.description,
        });
        resetForm();
    };

    const handleClose = (resetForm) => {
        resetForm();
        setOpenUploadModel(false);
    };

    return (
        <div className="bg-white w-full h-full flex flex-col">
            <div className="flex items-center justify-between bg-[#06173A] py-10 px-4">
                <Title className="text-white">Create Sender ID</Title>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ resetForm }) => (
                        <button onClick={() => handleClose(resetForm)}>
                            <MdClose color="white" size={24} />
                        </button>
                    )}
                </Formik>
            </div>
            {SenderIdLoading && <Loading />}

            <div className="px-8 h-full flex flex-col justify-between pb-4">
                {!SenderIdLoading && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, resetForm, setFieldValue, values }) => (
                            <Form className="flex flex-col h-full justify-between">
                                <Wrapper>
                                    <InputGroup>
                                        <Label htmlFor="senderId">Sender Id *</Label>
                                        <FieldWrappers>
                                            <Field
                                                type="text"
                                                name="senderId"
                                                id="senderId"
                                                autoComplete="off"
                                                className="uppercase"
                                                required
                                                maxLength="40"
                                                onChange={(e) => {
                                                    setFieldValue("senderId", e.target.value);
                                                    setBodyCharCount(e.target.value.length);
                                                }}
                                                value={values.senderId}
                                            />
                                            <MediaCharCount>{bodyCharCount}/40</MediaCharCount>
                                        </FieldWrappers>
                                        {errors.senderId && touched.senderId ? (
                                            <div className="text-red-500 text-sm mt-1">{errors.senderId}</div>
                                        ) : null}
                                    </InputGroup>
                                    <InputGroup>
                                        <Label htmlFor="description">Description *</Label>
                                        <FieldWrappers as="textarea" className="h-32">
                                            <Field
                                                as="textarea"
                                                name="description"
                                                id="description"
                                                className="w-full h-full pt-1"
                                                autoComplete="off"
                                                placeholder="Please write short details for company name or brand used for which you will use this sender Id"
                                                required
                                            />

                                        </FieldWrappers>
                                        {errors.description && touched.description ? (
                                            <div className="text-red-500 text-sm mt-1">{errors.description}</div>
                                        ) : null}
                                    </InputGroup>
                                </Wrapper>

                                <div className="flex justify-between p-4">
                                    <CancelBtn type="button" onClick={() => handleClose(resetForm)}>
                                        Cancel
                                    </CancelBtn>
                                    <BtnWrapper>
                                        <SubmitBtn type="submit">Create</SubmitBtn>
                                    </BtnWrapper>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

const Wrapper = tw.div`grid gap-6 my-6`;
const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;
const MediaCharCount = tw.div`absolute top-2.5 right-2 text-xs text-gray-400`;
export default CreateSenderId;
