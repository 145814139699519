// import React, { useState, useEffect } from "react";
// import { FaUserCircle } from "react-icons/fa";
// import { HiOutlineBell } from "react-icons/hi";
// import { LuHelpCircle } from "react-icons/lu";
// import { PiPulseBold } from "react-icons/pi";
// import tw from "tailwind-styled-components";
// import { useDispatch, useSelector } from "react-redux";
// import { Remove_User } from "../Redux/actions";
// import { Tooltip } from "@mui/material";
// import { Link } from "react-router-dom";
// import Config from "Config";

// const TopNavbar = ({ pageTitle, pageSubtitle }) => {
//   const user = useSelector((state) => state.UserReducer.user);
//   const dispatch = useDispatch();

//   const logout = () => dispatch(Remove_User());
//   const [showDropdown, setShowDropdown] = useState(false);

//   const toggleDropdown = () => {
//     setShowDropdown(!showDropdown);
//   };

//   const closeDropdown = () => {
//     setShowDropdown(false);
//   };

//   const handleClickOutside = (event) => {
//     if (
//       !event.target.closest(".dropdown-menu") &&
//       !event.target.closest(".user-icon")
//     ) {
//       closeDropdown();
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <NavbarContainer>
//       <div>
//         <Title>{pageTitle}</Title>
//         <SubTitle>{pageSubtitle}</SubTitle>
//       </div>
//       <NavbarContent>

//         {user.type == Config.UserType.ClientUser && (
//           <Btn className="flex items-center gap-2 group bg-yellow-100 rounded-2xl">
//             <PulseIcon className="group-hover:text-topBar-purple" />
//             <span className="font-semibold text-slate-800 text-base group-hover:text-topBar-purple pr-2">
//               Usage and Plan
//             </span>
//           </Btn>)}

//         <Tooltip title="Help">
//           <Btn>
//             <LuHelpCircle size={20} />
//           </Btn>
//         </Tooltip>
//         <Tooltip title="Notifications">
//           <Btn>
//             <HiOutlineBell size={20} />
//           </Btn>
//         </Tooltip>
//         <Tooltip title="Profile & more...">
//           <Btn className="group" onClick={toggleDropdown}>
//             <UserIcon className="user-icon" />
//           </Btn>
//         </Tooltip>
//         {showDropdown && (
//           <DropdownMenu className="dropdown-menu">
//             {/* <Triangle /> */}
//             <DropdownItem>Profile</DropdownItem>
//             {user?.type == Config.UserType.SuperAdminUser && (
//               <Link to="/blacklist/keywords" >
//                 <DropdownItem>Blacklist Keyword</DropdownItem>
//               </Link>)}
//             <DropdownItem
//               className="text-red-500 font-semibold"
//               onClick={logout}
//             >
//               Logout
//             </DropdownItem>
//           </DropdownMenu>
//         )}
//         {/* </IconsContainer> */}
//       </NavbarContent>
//     </NavbarContainer>
//   );
// };

// const NavbarContainer = tw.div`
//     bg-white h-14 px-8 flex justify-between items-center 
// `;
// const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
// const SubTitle = tw.h1`text-xs  text-gray-500 whitespace-nowrap   `;
// const NavbarContent = tw.div`
//     flex items-center gap-8
// `;

// const Btn = tw.button`hover:bg-violet-100 hover:text-topBar-purple px-2 py-2 hover:rounded-xl`;
// const IconsContainer = tw.div`
//     flex items-center gap-8 relative
// `;

// const BellIcon = tw(HiOutlineBell)`
//     text-slate-800 cursor-pointer text-xl
// `;
// const PulseIcon = tw(PiPulseBold)`
//     text-slate-800 cursor-pointer text-xl h-4 w-4
// `;
// const HelpIcon = tw(LuHelpCircle)`
//     text-slate-800 cursor-pointer text-xl h-5 w-5 hover:text-topBar-purple
// `;

// const UserIcon = tw(FaUserCircle)`
//     text-slate-800 cursor-pointer text-xl h-5 w-5 group-hover:text-topBar-purple
// `;

// const DropdownMenu = tw.div`
//     absolute top-10 right-0  w-40 bg-white border border-gray-200 shadow-md rounded-md py-2 z-10
// `;

// const DropdownItem = tw.div`
//     cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-full mx-2 text-sm
// `;

// const Triangle = tw.div`
//     w-5 h-5 bg-white absolute -top-3 right-1 transform rotate-45  border-l border-t border-gray-200
// `;

// export default TopNavbar;

import React, { useState, useEffect, useRef } from "react";
import { FaRegTimesCircle, FaUserCircle } from "react-icons/fa";
import { HiOutlineBell } from "react-icons/hi";
import { LuHelpCircle } from "react-icons/lu";
import { PiPulseBold } from "react-icons/pi";
import tw from "tailwind-styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Config from "Config";

const TopNavbar = ({ pageTitle, pageSubtitle }) => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();

  const logout = () => dispatch(Remove_User());
  const [showDropdown, setShowDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current && !modalRef.current.contains(event.target) &&
      !event.target.closest(".user-icon")
    ) {
      setShowDropdown(false);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpenModal = () => setOpenModal(!openModal);

  return (
    <NavbarContainer>
      <div>
        <Title>{pageTitle}</Title>
        <SubTitle>{pageSubtitle}</SubTitle>
      </div>
      <NavbarContent>

        {user.type == Config.UserType.ClientUser && (
          // <Btn className="flex items-center gap-2 group bg-yellow-100 rounded-2xl" onClick={handleOpenModal}>
          <Btn className="flex items-center gap-2 group bg-yellow-100 rounded-2xl" >
            <PulseIcon className="group-hover:text-topBar-purple" />
            <span className="font-semibold text-slate-800 text-base group-hover:text-topBar-purple pr-2">
              Usage and Plan
            </span>
          </Btn>)}

        <Tooltip title="Help">
          <Btn>
            <LuHelpCircle size={20} />
          </Btn>
        </Tooltip>
        <Tooltip title="Notifications">
          <Btn>
            <HiOutlineBell size={20} />
          </Btn>
        </Tooltip>
        <Tooltip title="Profile & more...">
          <Btn className="group" onClick={toggleDropdown}>
            <UserIcon className="user-icon" />
          </Btn>
        </Tooltip>
        {showDropdown && (
          <DropdownMenu className="dropdown-menu">
            {/* <Triangle /> */}
            <Link to="/account-settings" >
              <DropdownItem>Profile</DropdownItem>
            </Link>
            {user?.type == Config.UserType.SuperAdminUser && (
              <Link to="/blacklist/keywords" >
                <DropdownItem>Blacklist Keyword</DropdownItem>
              </Link>)}

            <DropdownItem
              className="text-red-500 font-semibold"
              onClick={logout}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        )}
        {/* </IconsContainer> */}
      </NavbarContent>

      {openModal && (
        <ModalContainer ref={modalRef}>
          <ModalHeader>
            <Heading>Campaign usage and plan</Heading>
            <CloseButton onClick={handleOpenModal}><FaRegTimesCircle /></CloseButton>
          </ModalHeader>
          <ModalContent>
            <div className="mb-2">
              <SubHeading>Free plan</SubHeading>
              <Paragraph>300 emails left until 07/08/2024</Paragraph>
              <Link to="/pricing" className="text-sm underline">Manage your plan and credits</Link>
            </div>
            <div className="mb-2">
              <SubHeading>Emails prepaid credits</SubHeading>
              <Paragraph>0 emails left</Paragraph>
              <Link to="/email-price" className="text-sm underline">Get more credits</Link>
            </div>
            <div className="mb-2">
              <SubHeading>WhatsApp and SMS</SubHeading>
              <Paragraph>0 credits left</Paragraph>
              <Link to="/messaging-price" className="text-sm underline">Get more credits</Link>
            </div>

          </ModalContent>
        </ModalContainer>
      )}
    </NavbarContainer>
  );
};

const NavbarContainer = tw.div`
    bg-white h-14 px-8 flex justify-between items-center 
`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const SubTitle = tw.h1`text-xs  text-gray-500 whitespace-nowrap   `;
const NavbarContent = tw.div`
    flex items-center gap-8
`;

const Btn = tw.button`hover:bg-violet-100 hover:text-topBar-purple px-2 py-2 hover:rounded-xl relative`;
const IconsContainer = tw.div`
    flex items-center gap-8 relative
`;

const BellIcon = tw(HiOutlineBell)`
    text-slate-800 cursor-pointer text-xl
`;
const PulseIcon = tw(PiPulseBold)`
    text-slate-800 cursor-pointer text-xl h-4 w-4
`;
const HelpIcon = tw(LuHelpCircle)`
    text-slate-800 cursor-pointer text-xl h-5 w-5 hover:text-topBar-purple
`;

const UserIcon = tw(FaUserCircle)`
    text-slate-800 cursor-pointer text-xl h-5 w-5 group-hover:text-topBar-purple
`;

const DropdownMenu = tw.div`
    absolute top-10 right-0  w-40 bg-white border border-gray-200 shadow-md rounded-md py-2 z-10
`;

const DropdownItem = tw.div`
    cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-full mx-2 text-sm
`;

const Triangle = tw.div`
    w-5 h-5 bg-white absolute -top-3 right-1 transform rotate-45  border-l border-t border-gray-200
`;

const ModalContainer = tw.div`
  absolute top-14 right-20 w-1/3 bg-white border border-gray-200 shadow-lg rounded-lg py-4 px-6 z-20
`;

const ModalHeader = tw.div`
  flex justify-between items-center mb-4
`;

const CloseButton = tw.span`
  cursor-pointer text-xl font-bold
`;

const ModalContent = tw.div`
  text-gray-800
`;
const Heading = tw.h2`text-xl font-semibold`;
const SubHeading = tw.h4`text-base font-semibold`;
const Paragraph = tw.p`text-sm`;

export default TopNavbar;
