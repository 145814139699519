import Images from "Images";

export const benefits = [
  {
    title: "Personalized Customer Experiences",
    description:
      "AI-driven content and multi-channel campaigns provide highly personalized marketing, enhancing customer loyalty and satisfaction.",
    image: Images.targetAudience,
  },
  {
    title: "Improved Guest Engagement",
    description:
      "With tools like surveys, polls, and social media feedback management, hotels and travel companies can continuously improve guest services.",
    image: Images.outreach,
  },
  {
    title: "Higher Conversion Rates",
    description:
      "Automated marketing and lead generation streamline the process of converting potential guests into customers, leading to increased bookings and revenue.",
    image: Images.conversation,
  },
  {
    title: "Seamless Multi-Channel Communication",
    description:
      "Combining SMS, email, voice, and social media ensures that guests can engage with brands on the channels they prefer.",
    image: Images.OmniChannel,
  },
  {
    title: "Enhanced Loyalty Programs",
    description:
      "Personalized rewards, special offers, and loyalty campaigns improve guest retention and drive repeat business.",
    image: Images.loyalty,
  },
  {
    title: "Increased Operational Efficiency",
    description:
      "Automation of booking confirmations, appointment management, and feedback collection reduces the burden on staff, allowing them to focus on delivering excellent service.",
    image: Images.operational,
  },
];


export const features = [
  {
    title: "Hotel Room and Special Offer Promotions",
    useCase: "Promote discounted room rates during off-peak seasons and special offers for holiday travel.",
    mechanics: "Hotels use Bulk SMS, Email Messaging, and Social Media Campaigns to promote discounted room rates and special holiday offers. Platforms like Facebook, Instagram, and WhatsApp are utilized for targeting based on geo-location and booking behaviors.",
    image: Images.religion_img_one, 
    benefit: "Increased bookings during low seasons and holidays, higher visibility for promotions, and personalized offers that boost customer retention.",
  },
  {
    title: "Restaurant Reservation and Dining Experience Management",
    useCase: "Confirm and remind customers about reservations and gather feedback on dining experiences.",
    mechanics: "Restaurants implement SMS and Email Reminders through Sales & Marketing Automation for reservations. Post-meal feedback is collected via Polling, Survey, and Feedback Management through Bulk SMS.",
    image: Images.religion_img_two, 
    benefit: "Reduced no-show rates, better dining experience through actionable feedback, and seamless communication with guests.",
  },
  {
    title: "Travel Package and Insurance Promotions",
    useCase: "Promote exclusive travel packages and upsell travel insurance.",
    mechanics: "Tour operators use Social Media Marketing and Sales & Marketing Automation to promote travel packages and upsell insurance. Platforms include Instagram, Facebook, and YouTube, with automated follow-ups for insurance.",
    image: Images.religion_img_three, 
    benefit: "Increased package sales, enhanced traveler safety, and higher revenue through upsells and engaging promotions.",
  },
  {
    title: "Guest Feedback Collection and Management",
    useCase: "Gather feedback from guests after their stay and manage large group reservations.",
    mechanics: "Resorts and hotels use Polling, Survey, and Feedback Management via SMS and Email. Voice Broadcasts are used for large group reservations to confirm details and provide information.",
    image: Images.religion_img_four, 
    benefit: "Enhanced guest satisfaction, better service improvement insights, and streamlined communication with large groups.",
  },
  {
    title: "Loyalty Programs and Personalized Offers",
    useCase: "Reward frequent travelers and offer personalized travel packages.",
    mechanics: "The Loyalty Management Campaign Module rewards frequent travelers with points and discounts. AI-powered content creation is used for personalized travel package offers sent via Bulk Email and SMS.",
    image: Images.religion_img_five, 
    benefit: "Increased customer loyalty, repeat business, and higher revenue from personalized offers and rewards.",
  },
  {
    title: "Event Promotion and Appointment Management",
    useCase: "Promote events at hotels and manage appointments for spa and wellness centers.",
    mechanics: "Social Media Campaign Management promotes hotel events. The Meetings & Appointment Management Module handles spa and wellness bookings, with reminders sent via SMS and Email.",
    image: Images.religion_img_six, 
    benefit: "Higher event bookings, better brand awareness, and improved scheduling efficiency for wellness treatments.",
  },
  {
    title: "Last-Minute Booking Alerts and Travel Information",
    useCase: "Notify customers about last-minute room availability and provide tourist information.",
    mechanics: "Hotels use Bulk SMS and Email to alert customers about last-minute bookings. USSD Menus offer easy access to tourist information and feedback collection.",
    image: Images.religion_img_seven, 
    benefit: "Higher occupancy rates, maximized revenue from unsold rooms, and improved traveler experience with accessible information.",
  },
  {
    title: "Consumer Feedback and Market Information",
    useCase: "Collect feedback on agricultural products and provide real-time market price alerts.",
    mechanics: "Polling, Survey, and Feedback Management is used to gather feedback from consumers. Bulk SMS and USSD Menus provide real-time market price alerts to farmers.",
    image: Images.religion_img_eight, 
    benefit: "Improved product quality, better pricing for crops, and enhanced customer satisfaction and loyalty.",
  },
  {
    title: "Automated Harvest Season and Conservation Campaigns",
    useCase: "Promote harvested produce and raise awareness for forestry conservation.",
    mechanics: "Sales & Marketing Automation promotes harvest season produce through Bulk SMS and Email. Bulk Messaging and Social Media are used for forestry conservation campaigns.",
    image: Images.religion_img_nine, 
    benefit: "Increased sales during harvest seasons, greater public awareness for conservation efforts, and higher demand for seasonal crops.",
  },
  {
    title: "Loyalty Programs and Organic Produce Subscriptions",
    useCase: "Reward customers at agro-supply stores and promote organic produce subscriptions.",
    mechanics: "Loyalty Management Campaigns use Bulk SMS and Email to reward customers. Social Media Marketing and Email Campaigns are used to promote organic produce subscriptions.",
    image: Images.religion_img_ten, 
    benefit: "Increased customer retention, higher sales from loyal customers, and better customer retention for organic farms.",
  },
];






