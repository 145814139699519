// src/components/StatCard.jsx
import React from 'react';
import tw from 'tailwind-styled-components';

const CardContainer = tw.div`bg-white  p-4 h-24  md:w-1/2 lg:w-1/4 md:flex items-center justify-center`;

const Title = tw.h4`text-xs font-medium text-gray-600`;
const Value = tw.h3`text-sm font-bold text-gray-800 `;
const Icon = tw.h4`h-10 `;
const StatCard = ({ title, value, icon: Icon, color }) => {
    return (
        <CardContainer>
            <div className="flex justify-center">
                <div className=" mr-4  rounded-full px-2 py-2" style={{ backgroundColor: color }}>
                    <Icon className="w-5 h-5 text-white" />
                </div>
                <div>
                    <Title>{title}</Title>
                    <Value>{value}</Value>
                </div>
            </div>
        </CardContainer>
    );
};

export default StatCard;

// src/components/StatCard.jsx
// import React from 'react';
// import tw from 'tailwind-styled-components';

// const CardContainer = tw.div`bg-white  p-4 h-24  md:w-1/2 lg:w-1/4 md:flex items-center justify-center`;

// const Title = tw.h4`text-xs font-medium text-gray-600`;
// const Value = tw.h3`text-sm font-bold text-gray-800 `;
// const Icon = tw.h4`h-10 `;
// const StatCard = ({ title, value, icon: Icon, color }) => {
//     return (
//         <CardContainer>
//             <div className="flex justify-center">
//                 <div className=" mr-4  rounded-full px-2 py-2" style={{ backgroundColor: color }}>
//                     <Icon className="w-5 h-5 text-white" />
//                 </div>
//                 <div>
//                     <Title>{title}</Title>
//                     <Value>{value}</Value>
//                 </div>
//             </div>
//         </CardContainer>
//     );
// };

// export default StatCard;
