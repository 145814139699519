// import React from 'react';
// import { useTable } from 'react-table';
// import tw from 'tailwind-styled-components';
// import useMedia from 'use-media';
// import { useNavigate } from 'react-router-dom';
// import { TbReportAnalytics } from 'react-icons/tb';
// import Config from 'Config';
// import { useSelector } from "react-redux";
// import moment from 'moment';
// import { FaRegCheckCircle, FaRegCircle, FaRegClock } from 'react-icons/fa';
// import { MdHistoryToggleOff } from 'react-icons/md';

// const TypeCell = ({ value }) => {
//     const types = {
//         1: 'SMS',
//         2: 'WhatsApp',
//         3: 'Voice',
//         4: 'Telegram',
//     };
//     return <span>{types[value] || 'Unknown'}</span>;
// };

// const StatusCell = ({ row }) => {
//     const { status, is_scheduled } = row.original;

//     if (status === 1 && is_scheduled === 1) {
//         return <ScheduleBadge><FaRegClock className="inline mr-1 w-3 h-3" /> Scheduled</ScheduleBadge>;
//     } else if (status === 2) {
//         return <RunningBadge ><MdHistoryToggleOff className="inline mr-1 w-3 h-3" />Running</RunningBadge>;
//     } else if (status === 3) {
//         return <SentBadge><FaRegCheckCircle className="inline mr-1 w-3 h-3" />Sent</SentBadge>;
//     } else {
//         return <PendingBadge><FaRegCircle className="inline mr-1 w-3 h-3" />Pending</PendingBadge>;
//     }
// };

// const ReportCell = ({ row }) => {
//     const navigate = useNavigate();
//     const { id, type } = row.original;

//     const handleNavigate = () => {
//         if (type === 1) {
//             navigate(`/campaign/sms/report/${id}`);
//         } else if (type === 2) {
//             navigate(`/campaign/whatsapp/report/${id}`);
//         } else if (type === 3) {
//             navigate(`/campaign/voice/report/${id}`);
//         } else if (type === 5) {
//             navigate(`/campaign/telegram/report/${id}`);
//         } else {
//             navigate(`/campaign/email/report/${id}`);
//         }
//     };

//     return (
//         <button
//             onClick={handleNavigate}
//             className="text-blue-500 hover:underline flex items-center gap-1"
//         >
//             <TbReportAnalytics /> View Report
//         </button>
//     );
// };

// const OrderStatus = ({ ApiData }) => {
//     const user = useSelector((state) => state.UserReducer.user);
//     const isMedium = useMedia({ maxWidth: '768px' });
//     const data = ApiData?.campaigns || [];

//     const columns = React.useMemo(() => {

//         const baseColumns = [
//             // { Header: 'Campaign Name', accessor: 'name' },
//             {
//                 Header: 'Campaign Name',
//                 accessor: 'name',
//                 Cell: ({ value }) => (
//                     <div className="w-40 truncate" title={value}>
//                         {value}
//                     </div>
//                 ),
//             },
//             { Header: 'Type', accessor: 'type', Cell: TypeCell },
//             { Header: 'Status', accessor: 'status', Cell: StatusCell },
//             { Header: 'Report', accessor: 'report', Cell: ReportCell },
//         ];

//         if (user.type === Config.UserType.SuperAdminUser) {
//             baseColumns.splice(1, 0, {
//                 Header: 'User Name', accessor: 'userName', Cell: ({ value }) => (
//                     <div className="w-28 truncate" title={value}>
//                         {value}
//                     </div>
//                 ),
//             });
//         } else {
//             baseColumns.splice(1, 0, { Header: 'Created At', accessor: 'created_at', Cell: ({ value }) => moment(value).format('MMMM Do YYYY') });
//         }

//         return baseColumns;
//     }, [user.type]);
//     const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

//     return (
//         <TableContainer>
//             <div className="flex justify-between items-center mb-4">
//                 <h2 className="text-lg font-bold">Recent Campaigns</h2>
//             </div>
//             {isMedium ? (
//                 <ListContainer>
//                     {rows.map(row => {
//                         prepareRow(row);
//                         return (
//                             <ListItem key={row.id}>
//                                 {row.cells.map(cell => (
//                                     <div key={cell.column.id}>
//                                         <strong>{cell.column.Header}: </strong>{cell.render('Cell')}
//                                     </div>
//                                 ))}
//                             </ListItem>
//                         );
//                     })}
//                 </ListContainer>
//             ) : (
//                 <Table {...getTableProps()}>
//                     <Thead>
//                         {headerGroups.map(headerGroup => (
//                             <tr {...headerGroup.getHeaderGroupProps()}>
//                                 {headerGroup.headers.map(column => (
//                                     <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
//                                 ))}
//                             </tr>
//                         ))}
//                     </Thead>
//                     <Tbody {...getTableBodyProps()}>
//                         {rows.map(row => {
//                             prepareRow(row);
//                             return (
//                                 <tr {...row.getRowProps()}>
//                                     {row.cells.map(cell => (
//                                         <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
//                                     ))}
//                                 </tr>
//                             );
//                         })}
//                     </Tbody>
//                 </Table>
//             )}
//         </TableContainer>
//     );
// };

// const TableContainer = tw.div`bg-white p-[1.10rem] shadow-md rounded-md w-full h-auto`;
// const Table = tw.table`min-w-full table-auto`;
// const Thead = tw.thead`bg-gray-200`;
// const Tbody = tw.tbody``;
// const Th = tw.th`px-4 py-2 text-left text-gray-600 text-sm font-medium uppercase tracking-wider`;
// const Td = tw.td`px-4 py-3 text-sm text-gray-700`;
// const ListContainer = tw.div`bg-white p-5 shadow-md rounded-md w-full`;
// const ListItem = tw.div`bg-gray-100 p-4 mb-2 rounded-md`;
// const SentBadge = tw.div`bg-blue-100 text-blue-700 text-xs px-2 py-1 flex items-center justify-center rounded-lg`;
// const ScheduleBadge = tw.div`bg-teal-100 text-teal-700 text-xs px-2 py-1 flex items-center justify-center rounded-lg`;
// const RunningBadge = tw.div`bg-green-100 text-green-700 text-xs px-2 py-1 flex items-center justify-center rounded-lg`;
// const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs px-2 py-1 flex items-center justify-center rounded-lg`;
// export default OrderStatus;



import React from 'react';
import { useTable } from 'react-table';
import tw from 'tailwind-styled-components';
import useMedia from 'use-media';

const TableContainer = tw.div`bg-white p-[1.10rem] shadow-md rounded-md w-full`;
const Table = tw.table`min-w-full table-auto`;
const Thead = tw.thead`bg-gray-200`;
const Tbody = tw.tbody``;
const Th = tw.th`px-4 py-2 text-left text-gray-600 text-sm font-medium uppercase tracking-wider`;
const Td = tw.td`px-4 py-3 text-sm text-gray-700`;

const ListContainer = tw.div`bg-white p-5 shadow-md rounded-md w-full`;
const ListItem = tw.div`bg-gray-100 p-4 mb-2 rounded-md`;

const data = [
    { invoice: '12366', customer: 'Charly Dues', from: 'Brazil', price: '$299', status: 'Process' },
    { invoice: '12366', customer: 'Marko', from: 'Italy', price: '$2642', status: 'Open' },
    { invoice: '12366', customer: 'Deniyel Onak', from: 'Russia', price: '$961', status: 'On Hold' },
    { invoice: '12366', customer: 'Belgiti Bastana', from: 'Korea', price: '$369', status: 'Process' },
    { invoice: '12366', customer: 'Sarit Onuska', from: 'Japan', price: '$1240', status: 'Open' },
    // { invoice: '12366', customer: 'Sarit Onuska', from: 'Japan', price: '$1240', status: 'Open' },
];

const StatusCell = ({ value }) => {
    let styles = '';
    let textStyles = '';

    if (value === 'Process') {
        styles = 'bg-[#F33922] text-white pt-1 pb-1.5 px-2 rounded-md border border-[#F33922]  !items-center';
        textStyles = 'text-xs';
    } else if (value === 'On Hold') {
        styles = 'bg-[#667ADD] text-white pt-1 pb-1.5 px-2 rounded-md border border-[#667ADD]  !items-center';
        textStyles = 'text-xs';
    } else if (value === 'Open') {
        styles = 'bg-[#4AA23C] text-white pt-1 pb-1.5 px-2 rounded-md border border-[#4AA23C]  !items-center';
        textStyles = 'text-xs';
    }

    return (
        <span className={`${styles} ${textStyles}`}>
            {value}
        </span>
    );
};


const columns = [
    { Header: 'INVOICE', accessor: 'invoice' },
    { Header: 'CUSTOMERS', accessor: 'customer' },
    { Header: 'FROM', accessor: 'from' },
    { Header: 'PRICE', accessor: 'price' },
    { Header: 'STATUS', accessor: 'status', Cell: StatusCell },
];

const OrderStatus = () => {
    const isMedium = useMedia({ maxWidth: '768px' });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <TableContainer>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Order Status</h2>
            </div>
            {isMedium ? (
                <ListContainer>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <ListItem key={row.id}>
                                {row.cells.map(cell => (
                                    <div key={cell.column.id}>
                                        <strong>{cell.column.Header}: </strong>{cell.render('Cell')}
                                    </div>
                                ))}
                            </ListItem>
                        );
                    })}
                </ListContainer>
            ) : (
                <Table {...getTableProps()}>
                    <Thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                                ))}
                            </tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                    ))}
                                </tr>
                            );
                        })}
                    </Tbody>
                </Table>
            )}
        </TableContainer>
    );
};

export default OrderStatus;
