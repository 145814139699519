import Images from "Images";

export const benefits = [
  {
    title: "Increased Customer Engagement",
    description:
      "The use of personalized SMS, email, and social media campaigns ensures that customers feel valued, leading to stronger engagement and retention.",
    image: Images.outreach,
  },
  {
    title: "Cross-Channel Reach",
    description:
      "SEVO allows businesses to reach customers across multiple channels, maximizing the impact of marketing campaigns.",
    image: Images.OmniChannel,
  },
  {
    title: "Enhanced Customer Insights",
    description:
      "The integration of surveys, polling, and feedback collection enables businesses to gather real-time insights into customer preferences, leading to data-driven decision-making.",
    image: Images.customerInsights,
  },
  {
    title: "Loyalty & Retention",
    description:
      "Loyalty management tools help build customer loyalty through personalized rewards and exclusive offers.",
    image: Images.loyalty,
  },
  {
    title: "Automation & Scalability",
    description:
      "Automation of campaigns, including scheduling and AI-powered content creation, allows businesses to efficiently scale their marketing efforts.",
    image: Images.automation,
  },
];


export const features = [
  {
    title: "New Product Line Promotion",
    useCase: "Inform existing customers and target new audiences about a new product line.",
    mechanics:
      "Use SEVO’s SMS Bulk Messaging, Email Campaigns, and Social Media Marketing. A Loyalty Campaign incentivizes early purchases with reward points.",
    image: Images.religion_img_one,
    benefit:
      "Increased awareness of new products, enhanced customer engagement, and early sales boost through loyalty incentives.",
  },
  {
    title: "Flash Sale Campaign",
    useCase: "Boost short-term sales with a time-sensitive flash sale.",
    mechanics:
      "Schedule a 24-hour flash sale across digital platforms. Use Bulk SMS and Email Messaging to notify customers, and Social Media Campaigns to promote the sale with time-sensitive offers. AI Content Creation optimizes promotional messages to create urgency.",
    image: Images.religion_img_two,
    benefit:
      "Higher conversion rates through urgency, broader reach across customer segments, and maximized short-term revenue.",
  },
  {
    title: "Customer Feedback and Improvement",
    useCase: "Collect feedback from customers to improve products and services.",
    mechanics:
      "Use the Survey & Polling Module to gather feedback from recent buyers on product satisfaction via SMS, Email, and USSD Survey Forms. Reporting & Analytics help assess the data.",
    image: Images.religion_img_three,
    benefit:
      "Valuable customer insights for product improvements, better customer experience, and data-driven decision-making for future campaigns.",
  },
  {
    title: "Regional Campaign Targeting",
    useCase: "Target specific regions for location-based promotions.",
    mechanics:
      "Run a Geo-Location Campaign to target customers in a specific region for a clearance sale. Integrate SMS Messaging, Social Media Ads, and Voice Broadcast to inform customers in specific locations.",
    image: Images.religion_img_four,
    benefit:
      "Precise targeting for location-specific campaigns, higher engagement with local customers, and increased foot traffic to regional stores.",
  },
  {
    title: "Loyalty Program for Frequent Shoppers",
    useCase: "Reward loyal customers to increase retention and sales.",
    mechanics:
      "Launch a Loyalty Management Campaign offering reward points for each purchase. Use Bulk SMS to notify customers about their points balance and redeemable offers. Email Campaigns share exclusive discounts for loyal customers.",
    image: Images.religion_img_five,
    benefit:
      "Increased customer retention, enhanced brand loyalty, and higher repeat purchase rates through rewards programs.",
  },
  {
    title: "Abandoned Cart Recovery Campaign",
    useCase: "Recover sales from customers who abandoned their carts.",
    mechanics:
      "Integrate SEVO’s Sales & Marketing Automation module to send automated Bulk Email and SMS reminders to customers who abandoned their carts. Use AI Content Creation to personalize follow-up messages to incentivize purchases.",
    image: Images.religion_img_six,
    benefit:
      "Higher recovery of abandoned carts, increased sales conversions, and reduced cart abandonment rates through automated follow-ups.",
  },
  {
    title: "New Store Opening Promotion",
    useCase: "Promote the grand opening of a new store location.",
    mechanics:
      "Use Geo-Location Campaign Management to promote the opening. SMS and Email Campaigns target customers within a 10-mile radius, offering exclusive opening-day deals. Voice Broadcast notifies VIP customers about special store tours.",
    image: Images.religion_img_seven,
    benefit:
      "Targeted local outreach, higher foot traffic on opening day, and increased awareness of the new location.",
  },
  {
    title: "Holiday Sale Campaign",
    useCase: "Promote discounts during holiday seasons to boost sales.",
    mechanics:
      "Run a Holiday Sale Campaign using Bulk Messaging, Social Media Marketing, and Voice Broadcast to promote discounts. The Campaign Scheduling Module automates posts on Instagram, Facebook, and TikTok. Customers receive personalized SMS notifications about holiday deals.",
    image: Images.religion_img_eight,
    benefit:
      "Increased holiday sales, seamless cross-channel campaign coordination, and personalized offers for higher engagement.",
  },
  {
    title: "End-of-Season Clearance",
    useCase: "Clear out inventory with end-of-season sales promotions.",
    mechanics:
      "Conduct an End-of-Season Sale using Bulk SMS, Email Campaigns, and Voice Broadcast to inform customers about clearance prices. Social Media Campaigns showcase clearance items, while USSD Polling gathers feedback on the customer experience.",
    image: Images.religion_img_nine,
    benefit:
      "Faster inventory clearance, higher footfall, and improved customer insights for future sales events.",
  },
  {
    title: "Exclusive Membership Program",
    useCase: "Create a membership program for VIP customers to drive loyalty.",
    mechanics:
      "Launch a membership program using the Loyalty Management Module, providing exclusive discounts and events for members. SMS and Email Messaging notify members of special offers, while the Survey Module gathers feedback on the program.",
    image: Images.religion_img_ten,
    benefit:
      "Strengthened relationships with high-value customers, increased revenue from exclusive deals, and valuable member insights.",
  },
];





